// // // // // import React, { useState } from "react";
// // // // // import "../../assets/css/register.css";

// // // // // function RegisterForm() {
// // // // //   const [formValues, setFormValues] = useState({
// // // // //     name: "",
// // // // //     email: "",
// // // // //     mobile: "",
// // // // //     gender: "",
// // // // //     location: "",
// // // // //     message: "",
// // // // //     course: "", // Add new state for course
// // // // //   });

// // // // //   const [errors, setErrors] = useState({});

// // // // //   const handleChange = (e) => {
// // // // //     const { name, value } = e.target;
// // // // //     setFormValues({ ...formValues, [name]: value });
// // // // //   };

// // // // //   const handleChange = (e) => {
// // // // //     const { name, value, files } = e.target;
// // // // //     setFormData((prevState) => ({
// // // // //       ...prevState,
// // // // //       [name]: files ? files[0] : value,
// // // // //     }));
// // // // //   };

// // // // //   const handleSendMessage = async () => {
// // // // //     const formDataToSend = new FormData();
// // // // //     formDataToSend.append("name", formData.name);
// // // // //     formDataToSend.append("email", formData.email);
// // // // //     formDataToSend.append("mobile", formData.mobile);
// // // // //     formDataToSend.append("gender", formData.gender);
// // // // //     formDataToSend.append("message", formData.message);
// // // // //     formDataToSend.append("course", formData.course);

// // // // //     try {
// // // // //       const response = await axios.post(
// // // // //         "http://192.168.1.241:5000/api/send-email",
// // // // //         formDataToSend,
// // // // //         {
// // // // //           headers: {
// // // // //             "Content-Type": "multipart/form-data",
// // // // //           },
// // // // //         }
// // // // //       );
// // // // //       alert(response.data.message);
// // // // //     } catch (error) {
// // // // //       console.error("There was an error sending the message!", error);
// // // // //       alert("Failed to send message");
// // // // //     }
// // // // //   };

// // // // //   const validateForm = () => {
// // // // //     const newErrors = {};
// // // // //     if (!formValues.name) newErrors.name = "Name is required";
// // // // //     if (!formValues.email) newErrors.email = "Email is required";
// // // // //     else if (!/\S+@\S+\.\S+/.test(formValues.email))
// // // // //       newErrors.email = "Email address is invalid";
// // // // //     if (!formValues.mobile) newErrors.mobile = "Mobile number is required";
// // // // //     else if (!/^\d{10}$/.test(formValues.mobile))
// // // // //       newErrors.mobile = "Mobile number must be 10 digits";
// // // // //     if (!formValues.gender) newErrors.gender = "Gender is required";
// // // // //     if (!formValues.location) newErrors.location = "Location is required";
// // // // //     if (!formValues.message) newErrors.message = "Message is required";
// // // // //     if (!formValues.course) newErrors.course = "Course selection is required"; // Add validation for course
// // // // //     return newErrors;
// // // // //   };

// // // // //   const handleSubmit = (e) => {
// // // // //     e.preventDefault();
// // // // //     const formErrors = validateForm();
// // // // //     if (Object.keys(formErrors).length === 0) {
// // // // //       console.log("Form submitted:", formValues);
// // // // //     } else {
// // // // //       setErrors(formErrors);
// // // // //     }
// // // // //   };

// // // // //   return (
// // // // //     <>
// // // // //       <div
// // // // //         className="backgroundCommenImage mb-5"
// // // // //         style={{
// // // // //           backgroundImage: `url(${require("../../assets/images/Register/reg_banner.jpg")})`,
// // // // //           backgroundSize: "cover",
// // // // //           backgroundPosition: "center",
// // // // //         }}
// // // // //       >
// // // // //         <div className="backgroundcommenImageBannerText">
// // // // //           <h1 className="text-warning text-center py-5">Register</h1>
// // // // //         </div>
// // // // //       </div>

// // // // //       <div className="container-fluid  ">
// // // // //         <p className="text-warning text-center mb-0 py-3 h1">
// // // // //           Explore Our Exciting Courses!
// // // // //         </p>
// // // // //       </div>

// // // // //       <div className="container mt-5 mb-5 ">
// // // // //         <div className="row">
// // // // //           <div className="col-lg-6">
// // // // //             {/* <div className='card'> */}
// // // // //             <div className="mt-5">
// // // // //               <div className="card register_imageSection ">
// // // // //                 <img
// // // // //                   src={require("../../assets/images/Register/form img 1.jpg")}
// // // // //                   style={{ height: "400px" }}
// // // // //                 ></img>
// // // // //               </div>
// // // // //             </div>

// // // // //             <div className="p-1 rounded mt-3 register_column_text ">
// // // // //               <p className="text-dark ">
// // // // //                 At Scimitar Hindustan, we offer exciting industrial automation
// // // // //                 and digital marketing courses for college students to gain
// // // // //                 valuable knowledge and hands-on experience in various domains
// // // // //                 like PLC Programming Fundamentals, Process Control and
// // // // //                 Optimization, Robotics Integration, and more. Our courses
// // // // //                 provide students with the opportunity to work on real-world
// // // // //                 projects, collaborate with industry professionals, and develop
// // // // //                 essential skills for their future careers.
// // // // //               </p>
// // // // //             </div>

// // // // //             {/* </div> */}
// // // // //           </div>

// // // // //           <div className="col-lg-6">
// // // // //             <div className="card register_form_card p-5 bg-success text-white">
// // // // //               <form className="Register_form_tag" onSubmit={handleSubmit}>
// // // // //                 <div className="mb-3">
// // // // //                   <label htmlFor="name" className="form-label ">
// // // // //                     Name:
// // // // //                   </label>
// // // // //                   <input
// // // // //                     id="name"
// // // // //                     name="name"
// // // // //                     className="form-control "
// // // // //                     type="text"
// // // // //                     placeholder="Enter your name"
// // // // //                     value={formValues.name}
// // // // //                     onChange={handleChange}
// // // // //                   />
// // // // //                   {errors.name && (
// // // // //                     <div className="text-warning">{errors.name}</div>
// // // // //                   )}
// // // // //                 </div>
// // // // //                 <div className="mb-3">
// // // // //                   <label htmlFor="email" className="form-label">
// // // // //                     Email Address:
// // // // //                   </label>
// // // // //                   <input
// // // // //                     id="email"
// // // // //                     name="email"
// // // // //                     className="form-control "
// // // // //                     type="email"
// // // // //                     placeholder="Enter your email address"
// // // // //                     value={formValues.email}
// // // // //                     onChange={handleChange}
// // // // //                   />
// // // // //                   {errors.email && (
// // // // //                     <div className="text-warning">{errors.email}</div>
// // // // //                   )}
// // // // //                 </div>
// // // // //                 <div className="mb-3 row">
// // // // //                   <div className="col-lg-6">
// // // // //                     <label htmlFor="mobile" className="form-label">
// // // // //                       Mobile:
// // // // //                     </label>
// // // // //                     <input
// // // // //                       id="mobile"
// // // // //                       name="mobile"
// // // // //                       className="form-control"
// // // // //                       type="tel"
// // // // //                       placeholder="Enter your mobile number"
// // // // //                       value={formValues.mobile}
// // // // //                       onChange={handleChange}
// // // // //                     />
// // // // //                     {errors.mobile && (
// // // // //                       <div className="text-warning">{errors.mobile}</div>
// // // // //                     )}
// // // // //                   </div>
// // // // //                   <div className="col-lg-6">
// // // // //                     <label htmlFor="gender" className="form-label ">
// // // // //                       Gender:
// // // // //                     </label>
// // // // //                     <select
// // // // //                       id="gender"
// // // // //                       name="gender"
// // // // //                       className="form-select"
// // // // //                       value={formValues.gender}
// // // // //                       onChange={handleChange}
// // // // //                     >
// // // // //                       <option value="">Select Gender</option>
// // // // //                       <option value="male">Male</option>
// // // // //                       <option value="female">Female</option>
// // // // //                       <option value="other">Other</option>
// // // // //                     </select>
// // // // //                     {errors.gender && (
// // // // //                       <div className="text-warning">{errors.gender}</div>
// // // // //                     )}
// // // // //                   </div>
// // // // //                 </div>
// // // // //                 <div className="mb-3">
// // // // //                   <label htmlFor="course" className="form-label">
// // // // //                     Choose Course:
// // // // //                   </label>
// // // // //                   <select
// // // // //                     id="course"
// // // // //                     name="course"
// // // // //                     className="form-select"
// // // // //                     value={formValues.course}
// // // // //                     onChange={handleChange}
// // // // //                   >
// // // // //                     <option value="">Select Course</option>
// // // // //                     <option value="Introduction to Automation">
// // // // //                       Introduction to Automation
// // // // //                     </option>
// // // // //                     <option value="PLC Programming Fundamentals">
// // // // //                       PLC Programming Fundamentals
// // // // //                     </option>
// // // // //                     <option value="Sensors and Actuators">
// // // // //                       Sensors and Actuators
// // // // //                     </option>
// // // // //                     <option value="Advanced PLC Programming">
// // // // //                       Advanced PLC Programming
// // // // //                     </option>
// // // // //                     <option value="SCADA Systems and Data Acquisition">
// // // // //                       SCADA Systems and Data Acquisition
// // // // //                     </option>
// // // // //                     <option value="Robotics Integration">
// // // // //                       Robotics Integration
// // // // //                     </option>
// // // // //                     <option value="  Industrial Networking and Communication">
// // // // //                       Industrial Networking and Communication
// // // // //                     </option>
// // // // //                     <option value="Process Control and Optimization">
// // // // //                       Process Control and Optimization
// // // // //                     </option>
// // // // //                     <option value="Automation System Design">
// // // // //                       Automation System Design
// // // // //                     </option>
// // // // //                   </select>
// // // // //                   {errors.course && (
// // // // //                     <div className="text-warning">{errors.course}</div>
// // // // //                   )}
// // // // //                 </div>
// // // // //                 <div className="mb-3">
// // // // //                   <label htmlFor="location" className="form-label">
// // // // //                     Location:
// // // // //                   </label>
// // // // //                   <input
// // // // //                     id="location"
// // // // //                     name="location"
// // // // //                     className="form-control"
// // // // //                     type="text"
// // // // //                     placeholder="Enter your location"
// // // // //                     value={formValues.location}
// // // // //                     onChange={handleChange}
// // // // //                   />
// // // // //                   {errors.location && (
// // // // //                     <div className="text-warning">{errors.location}</div>
// // // // //                   )}
// // // // //                 </div>
// // // // //                 <div className="mb-3">
// // // // //                   <label htmlFor="message" className="form-label">
// // // // //                     Message:
// // // // //                   </label>
// // // // //                   <textarea
// // // // //                     id="message"
// // // // //                     name="message"
// // // // //                     className="form-control"
// // // // //                     rows="2"
// // // // //                     placeholder="Enter your message"
// // // // //                     value={formValues.message}
// // // // //                     onChange={handleChange}
// // // // //                   ></textarea>
// // // // //                   {errors.message && (
// // // // //                     <div className="text-warning">{errors.message}</div>
// // // // //                   )}
// // // // //                 </div>{" "}
// // // // //                 <div className="text-center">
// // // // //                   <button type="submit" className="btn btn-warning">
// // // // //                     Submit
// // // // //                   </button>
// // // // //                 </div>
// // // // //               </form>
// // // // //             </div>
// // // // //           </div>
// // // // //         </div>
// // // // //       </div>
// // // // //     </>
// // // // //   );
// // // // // }

// // // // // export default RegisterForm;

// // // // import React, { useState } from "react";
// // // // import axios from "axios"; // Import axios for making HTTP requests
// // // // import "../../assets/css/register.css";

// // // // function RegisterForm() {
// // // //   const [formValues, setFormValues] = useState({
// // // //     name: "",
// // // //     email: "",
// // // //     mobile: "",
// // // //     gender: "",
// // // //     location: "",
// // // //     message: "",
// // // //     course: "",
// // // //   });

// // // //   const [errors, setErrors] = useState({});

// // // //   const handleChange = (e) => {
// // // //     const { name, value, files } = e.target;
// // // //     setFormValues((prevState) => ({
// // // //       ...prevState,
// // // //       [name]: files ? files[0] : value,
// // // //     }));
// // // //   };

// // // //   const handleSendMessage = async () => {
// // // //     const formDataToSend = new FormData();
// // // //     formDataToSend.append("name", formValues.name);
// // // //     formDataToSend.append("email", formValues.email);
// // // //     formDataToSend.append("mobile", formValues.mobile);
// // // //     formDataToSend.append("gender", formValues.gender);
// // // //     formDataToSend.append("message", formValues.message);
// // // //     formDataToSend.append("course", formValues.course);

// // // //     try {
// // // //       const response = await axios.post(
// // // //         "http://192.168.1.241:3000/api/send-email",
// // // //         formDataToSend,
// // // //         {
// // // //           headers: {
// // // //             "Content-Type": "multipart/form-data",
// // // //           },
// // // //         }
// // // //       );
// // // //       alert(response.data.message);
// // // //     } catch (error) {
// // // //       console.error("There was an error sending the message!", error);
// // // //       alert("Failed to send message");
// // // //     }
// // // //   };

// // // //   const validateForm = () => {
// // // //     const newErrors = {};
// // // //     if (!formValues.name) newErrors.name = "Name is required";
// // // //     if (!formValues.email) newErrors.email = "Email is required";
// // // //     else if (!/\S+@\S+\.\S+/.test(formValues.email))
// // // //       newErrors.email = "Email address is invalid";
// // // //     if (!formValues.mobile) newErrors.mobile = "Mobile number is required";
// // // //     else if (!/^\d{10}$/.test(formValues.mobile))
// // // //       newErrors.mobile = "Mobile number must be 10 digits";
// // // //     if (!formValues.gender) newErrors.gender = "Gender is required";
// // // //     if (!formValues.location) newErrors.location = "Location is required";
// // // //     if (!formValues.message) newErrors.message = "Message is required";
// // // //     if (!formValues.course) newErrors.course = "Course selection is required";
// // // //     return newErrors;
// // // //   };

// // // //   const handleSubmit = (e) => {
// // // //     e.preventDefault();
// // // //     const formErrors = validateForm();
// // // //     if (Object.keys(formErrors).length === 0) {
// // // //       handleSendMessage(); // Call handleSendMessage when the form is valid
// // // //     } else {
// // // //       setErrors(formErrors);
// // // //     }
// // // //   };

// // // //   return (
// // // //     <>
// // // //       <div
// // // //         className="backgroundCommenImage mb-5"
// // // //         style={{
// // // //           backgroundImage: `url(${require("../../assets/images/Register/reg_banner.jpg")})`,
// // // //           backgroundSize: "cover",
// // // //           backgroundPosition: "center",
// // // //         }}
// // // //       >
// // // //         <div className="backgroundcommenImageBannerText">
// // // //           <h1 className="text-warning text-center py-5">Register</h1>
// // // //         </div>
// // // //       </div>

// // // //       <div className="container-fluid">
// // // //         <p className="text-warning text-center mb-0 py-3 h1">
// // // //           Explore Our Exciting Courses!
// // // //         </p>
// // // //       </div>

// // // //       <div className="container mt-5 mb-5">
// // // //         <div className="row">
// // // //           <div className="col-lg-6">
// // // //             <div className="mt-5">
// // // //               <div className="card register_imageSection">
// // // //                 <img
// // // //                   src={require("../../assets/images/Register/form img 1.jpg")}
// // // //                   alt="Register"
// // // //                   style={{ height: "400px" }}
// // // //                 />
// // // //               </div>
// // // //             </div>

// // // //             <div className="p-1 rounded mt-3 register_column_text">
// // // //               <p className="text-dark">
// // // //                 At Scimitar Hindustan, we offer exciting industrial automation
// // // //                 and digital marketing courses for college students to gain
// // // //                 valuable knowledge and hands-on experience in various domains
// // // //                 like PLC Programming Fundamentals, Process Control and
// // // //                 Optimization, Robotics Integration, and more. Our courses
// // // //                 provide students with the opportunity to work on real-world
// // // //                 projects, collaborate with industry professionals, and develop
// // // //                 essential skills for their future careers.
// // // //               </p>
// // // //             </div>
// // // //           </div>

// // // //           <div className="col-lg-6">
// // // //             <div className="card register_form_card p-5 bg-success text-white">
// // // //               <form className="Register_form_tag" onSubmit={handleSubmit}>
// // // //                 <div className="mb-3">
// // // //                   <label htmlFor="name" className="form-label">
// // // //                     Name:
// // // //                   </label>
// // // //                   <input
// // // //                     id="name"
// // // //                     name="name"
// // // //                     className="form-control"
// // // //                     type="text"
// // // //                     placeholder="Enter your name"
// // // //                     value={formValues.name}
// // // //                     onChange={handleChange}
// // // //                   />
// // // //                   {errors.name && (
// // // //                     <div className="text-warning">{errors.name}</div>
// // // //                   )}
// // // //                 </div>
// // // //                 <div className="mb-3">
// // // //                   <label htmlFor="email" className="form-label">
// // // //                     Email Address:
// // // //                   </label>
// // // //                   <input
// // // //                     id="email"
// // // //                     name="email"
// // // //                     className="form-control"
// // // //                     type="email"
// // // //                     placeholder="Enter your email address"
// // // //                     value={formValues.email}
// // // //                     onChange={handleChange}
// // // //                   />
// // // //                   {errors.email && (
// // // //                     <div className="text-warning">{errors.email}</div>
// // // //                   )}
// // // //                 </div>
// // // //                 <div className="mb-3 row">
// // // //                   <div className="col-lg-6">
// // // //                     <label htmlFor="mobile" className="form-label">
// // // //                       Mobile:
// // // //                     </label>
// // // //                     <input
// // // //                       id="mobile"
// // // //                       name="mobile"
// // // //                       className="form-control"
// // // //                       type="tel"
// // // //                       placeholder="Enter your mobile number"
// // // //                       value={formValues.mobile}
// // // //                       onChange={handleChange}
// // // //                     />
// // // //                     {errors.mobile && (
// // // //                       <div className="text-warning">{errors.mobile}</div>
// // // //                     )}
// // // //                   </div>
// // // //                   <div className="col-lg-6">
// // // //                     <label htmlFor="gender" className="form-label">
// // // //                       Gender:
// // // //                     </label>
// // // //                     <select
// // // //                       id="gender"
// // // //                       name="gender"
// // // //                       className="form-select"
// // // //                       value={formValues.gender}
// // // //                       onChange={handleChange}
// // // //                     >
// // // //                       <option value="">Select Gender</option>
// // // //                       <option value="male">Male</option>
// // // //                       <option value="female">Female</option>
// // // //                       <option value="other">Other</option>
// // // //                     </select>
// // // //                     {errors.gender && (
// // // //                       <div className="text-warning">{errors.gender}</div>
// // // //                     )}
// // // //                   </div>
// // // //                 </div>
// // // //                 <div className="mb-3">
// // // //                   <label htmlFor="course" className="form-label">
// // // //                     Choose Course:
// // // //                   </label>
// // // //                   <select
// // // //                     id="course"
// // // //                     name="course"
// // // //                     className="form-select"
// // // //                     value={formValues.course}
// // // //                     onChange={handleChange}
// // // //                   >
// // // //                     <option value="">Select Course</option>
// // // //                     <option value="Introduction to Automation">
// // // //                       Introduction to Automation
// // // //                     </option>
// // // //                     <option value="PLC Programming Fundamentals">
// // // //                       PLC Programming Fundamentals
// // // //                     </option>
// // // //                     <option value="Sensors and Actuators">
// // // //                       Sensors and Actuators
// // // //                     </option>
// // // //                     <option value="Advanced PLC Programming">
// // // //                       Advanced PLC Programming
// // // //                     </option>
// // // //                     <option value="SCADA Systems and Data Acquisition">
// // // //                       SCADA Systems and Data Acquisition
// // // //                     </option>
// // // //                     <option value="Robotics Integration">
// // // //                       Robotics Integration
// // // //                     </option>
// // // //                     <option value="Industrial Networking and Communication">
// // // //                       Industrial Networking and Communication
// // // //                     </option>
// // // //                     <option value="Process Control and Optimization">
// // // //                       Process Control and Optimization
// // // //                     </option>
// // // //                     <option value="Automation System Design">
// // // //                       Automation System Design
// // // //                     </option>
// // // //                   </select>
// // // //                   {errors.course && (
// // // //                     <div className="text-warning">{errors.course}</div>
// // // //                   )}
// // // //                 </div>
// // // //                 <div className="mb-3">
// // // //                   <label htmlFor="location" className="form-label">
// // // //                     Location:
// // // //                   </label>
// // // //                   <input
// // // //                     id="location"
// // // //                     name="location"
// // // //                     className="form-control"
// // // //                     type="text"
// // // //                     placeholder="Enter your location"
// // // //                     value={formValues.location}
// // // //                     onChange={handleChange}
// // // //                   />
// // // //                   {errors.location && (
// // // //                     <div className="text-warning">{errors.location}</div>
// // // //                   )}
// // // //                 </div>
// // // //                 <div className="mb-3">
// // // //                   <label htmlFor="message" className="form-label">
// // // //                     Message:
// // // //                   </label>
// // // //                   <textarea
// // // //                     id="message"
// // // //                     name="message"
// // // //                     className="form-control"
// // // //                     rows="2"
// // // //                     placeholder="Enter your message"
// // // //                     value={formValues.message}
// // // //                     onChange={handleChange}
// // // //                   ></textarea>
// // // //                   {errors.message && (
// // // //                     <div className="text-warning">{errors.message}</div>
// // // //                   )}
// // // //                 </div>
// // // //                 <div className="text-center">
// // // //                   <button
// // // //                     type="submit"
// // // //                     className="btn btn-warning"
// // // //                     onClick={handleSendMessage}
// // // //                   >
// // // //                     Submit
// // // //                   </button>
// // // //                 </div>
// // // //               </form>
// // // //             </div>
// // // //           </div>
// // // //         </div>
// // // //       </div>
// // // //     </>
// // // //   );
// // // // }

// // // // export default RegisterForm;

// // // import React, { useState } from "react";
// // // import axios from "axios";
// // // import "../../assets/css/register.css";

// // // function RegisterForm() {
// // //   const [formValues, setFormValues] = useState({
// // //     name: "",
// // //     email: "",
// // //     mobile: "",
// // //     gender: "",
// // //     location: "",
// // //     message: "",
// // //     course: "",
// // //   });

// // //   const [errors, setErrors] = useState({});

// // //   const handleChange = (e) => {
// // //     const { name, value, files } = e.target;
// // //     setFormValues((prevState) => ({
// // //       ...prevState,
// // //       [name]: files ? files[0] : value,
// // //     }));
// // //   };

// // //   const handleSendMessage = async () => {
// // //     const formDataToSend = new FormData();
// // //     formDataToSend.append("name", formValues.name);
// // //     formDataToSend.append("email", formValues.email);
// // //     formDataToSend.append("mobile", formValues.mobile);
// // //     formDataToSend.append("gender", formValues.gender);
// // //     formDataToSend.append("message", formValues.message);
// // //     formDataToSend.append("course", formValues.course);

// // //     try {
// // //       const response = await axios.post(
// // //         "http://192.168.1.241:5000/api/send-email",
// // //         formDataToSend,
// // //         {
// // //           headers: {
// // //             "Content-Type": "multipart/form-data",
// // //           },
// // //         }
// // //       );

// // //       return response; // Return the response to handle it in handleSubmit
// // //     } catch (error) {
// // //       throw error; // Rethrow error to be caught in handleSubmit
// // //     }
// // //   };

// // //   const validateForm = () => {
// // //     const newErrors = {};
// // //     if (!formValues.name) newErrors.name = "Name is required";
// // //     if (!formValues.email) newErrors.email = "Email is required";
// // //     else if (!/\S+@\S+\.\S+/.test(formValues.email))
// // //       newErrors.email = "Email address is invalid";
// // //     if (!formValues.mobile) newErrors.mobile = "Mobile number is required";
// // //     else if (!/^\d{10}$/.test(formValues.mobile))
// // //       newErrors.mobile = "Mobile number must be 10 digits";
// // //     if (!formValues.gender) newErrors.gender = "Gender is required";
// // //     if (!formValues.location) newErrors.location = "Location is required";
// // //     if (!formValues.message) newErrors.message = "Message is required";
// // //     if (!formValues.course) newErrors.course = "Course selection is required";
// // //     return newErrors;
// // //   };

// // //   const handleSubmit = async (e) => {
// // //     e.preventDefault();
// // //     const formErrors = validateForm();
// // //     if (Object.keys(formErrors).length === 0) {
// // //       try {
// // //         const response = await handleSendMessage();
// // //         alert(response.data.message);
// // //       } catch (error) {
// // //         console.error("There was an error sending the message!", error);
// // //         alert("Failed to send message");
// // //       }
// // //     } else {
// // //       setErrors(formErrors);
// // //     }
// // //   };

// // //   return (
// // //     <>
// // //       <div
// // //         className="backgroundCommenImage mb-5"
// // //         style={{
// // //           backgroundImage: `url(${require("../../assets/images/Register/reg_banner.jpg")})`,
// // //           backgroundSize: "cover",
// // //           backgroundPosition: "center",
// // //         }}
// // //       >
// // //         <div className="backgroundcommenImageBannerText">
// // //           <h1 className="text-warning text-center py-5">Register</h1>
// // //         </div>
// // //       </div>

// // //       <div className="container-fluid">
// // //         <p className="text-warning text-center mb-0 py-3 h1">
// // //           Explore Our Exciting Courses!
// // //         </p>
// // //       </div>

// // //       <div className="container mt-5 mb-5">
// // //         <div className="row">
// // //           <div className="col-lg-6">
// // //             <div className="mt-5">
// // //               <div className="card register_imageSection">
// // //                 <img
// // //                   src={require("../../assets/images/Register/form img 1.jpg")}
// // //                   alt="Register"
// // //                   style={{ height: "400px" }}
// // //                 />
// // //               </div>
// // //             </div>

// // //             <div className="p-1 rounded mt-3 register_column_text">
// // //               <p className="text-dark">
// // //                 At Scimitar Hindustan, we offer exciting industrial automation
// // //                 and digital marketing courses for college students to gain
// // //                 valuable knowledge and hands-on experience in various domains
// // //                 like PLC Programming Fundamentals, Process Control and
// // //                 Optimization, Robotics Integration, and more. Our courses
// // //                 provide students with the opportunity to work on real-world
// // //                 projects, collaborate with industry professionals, and develop
// // //                 essential skills for their future careers.
// // //               </p>
// // //             </div>
// // //           </div>

// // //           <div className="col-lg-6">
// // //             <div className="card register_form_card p-5 bg-success text-white">
// // //               <form className="Register_form_tag" onSubmit={handleSubmit}>
// // //                 <div className="mb-3">
// // //                   <label htmlFor="name" className="form-label">
// // //                     Name:
// // //                   </label>
// // //                   <input
// // //                     id="name"
// // //                     name="name"
// // //                     className="form-control"
// // //                     type="text"
// // //                     placeholder="Enter your name"
// // //                     value={formValues.name}
// // //                     onChange={handleChange}
// // //                   />
// // //                   {errors.name && (
// // //                     <div className="text-warning">{errors.name}</div>
// // //                   )}
// // //                 </div>
// // //                 <div className="mb-3">
// // //                   <label htmlFor="email" className="form-label">
// // //                     Email Address:
// // //                   </label>
// // //                   <input
// // //                     id="email"
// // //                     name="email"
// // //                     className="form-control"
// // //                     type="email"
// // //                     placeholder="Enter your email address"
// // //                     value={formValues.email}
// // //                     onChange={handleChange}
// // //                   />
// // //                   {errors.email && (
// // //                     <div className="text-warning">{errors.email}</div>
// // //                   )}
// // //                 </div>
// // //                 <div className="mb-3 row">
// // //                   <div className="col-lg-6">
// // //                     <label htmlFor="mobile" className="form-label">
// // //                       Mobile:
// // //                     </label>
// // //                     <input
// // //                       id="mobile"
// // //                       name="mobile"
// // //                       className="form-control"
// // //                       type="tel"
// // //                       placeholder="Enter your mobile number"
// // //                       value={formValues.mobile}
// // //                       onChange={handleChange}
// // //                     />
// // //                     {errors.mobile && (
// // //                       <div className="text-warning">{errors.mobile}</div>
// // //                     )}
// // //                   </div>
// // //                   <div className="col-lg-6">
// // //                     <label htmlFor="gender" className="form-label">
// // //                       Gender:
// // //                     </label>
// // //                     <select
// // //                       id="gender"
// // //                       name="gender"
// // //                       className="form-select"
// // //                       value={formValues.gender}
// // //                       onChange={handleChange}
// // //                     >
// // //                       <option value="">Select Gender</option>
// // //                       <option value="male">Male</option>
// // //                       <option value="female">Female</option>
// // //                       <option value="other">Other</option>
// // //                     </select>
// // //                     {errors.gender && (
// // //                       <div className="text-warning">{errors.gender}</div>
// // //                     )}
// // //                   </div>
// // //                 </div>
// // //                 <div className="mb-3">
// // //                   <label htmlFor="course" className="form-label">
// // //                     Choose Course:
// // //                   </label>
// // //                   <select
// // //                     id="course"
// // //                     name="course"
// // //                     className="form-select"
// // //                     value={formValues.course}
// // //                     onChange={handleChange}
// // //                   >
// // //                     <option value="">Select Course</option>
// // //                     <option value="Introduction to Automation">
// // //                       Introduction to Automation
// // //                     </option>
// // //                     <option value="PLC Programming Fundamentals">
// // //                       PLC Programming Fundamentals
// // //                     </option>
// // //                     <option value="Sensors and Actuators">
// // //                       Sensors and Actuators
// // //                     </option>
// // //                     <option value="Advanced PLC Programming">
// // //                       Advanced PLC Programming
// // //                     </option>
// // //                     <option value="SCADA Systems and Data Acquisition">
// // //                       SCADA Systems and Data Acquisition
// // //                     </option>
// // //                     <option value="Robotics Integration">
// // //                       Robotics Integration
// // //                     </option>
// // //                     <option value="Industrial Networking and Communication">
// // //                       Industrial Networking and Communication
// // //                     </option>
// // //                     <option value="Process Control and Optimization">
// // //                       Process Control and Optimization
// // //                     </option>
// // //                     <option value="Automation System Design">
// // //                       Automation System Design
// // //                     </option>
// // //                   </select>
// // //                   {errors.course && (
// // //                     <div className="text-warning">{errors.course}</div>
// // //                   )}
// // //                 </div>
// // //                 <div className="mb-3">
// // //                   <label htmlFor="location" className="form-label">
// // //                     Location:
// // //                   </label>
// // //                   <input
// // //                     id="location"
// // //                     name="location"
// // //                     className="form-control"
// // //                     type="text"
// // //                     placeholder="Enter your location"
// // //                     value={formValues.location}
// // //                     onChange={handleChange}
// // //                   />
// // //                   {errors.location && (
// // //                     <div className="text-warning">{errors.location}</div>
// // //                   )}
// // //                 </div>
// // //                 <div className="mb-3">
// // //                   <label htmlFor="message" className="form-label">
// // //                     Message:
// // //                   </label>
// // //                   <textarea
// // //                     id="message"
// // //                     name="message"
// // //                     className="form-control"
// // //                     rows="2"
// // //                     placeholder="Enter your message"
// // //                     value={formValues.message}
// // //                     onChange={handleChange}
// // //                   ></textarea>
// // //                   {errors.message && (
// // //                     <div className="text-warning">{errors.message}</div>
// // //                   )}
// // //                 </div>
// // //                 <div className="text-center">
// // //                   <button type="submit" className="btn btn-warning">
// // //                     Submit
// // //                   </button>
// // //                 </div>
// // //               </form>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </div>
// // //     </>
// // //   );
// // // }

// // // export default RegisterForm;

// // import React, { useState } from "react";
// // import axios from "axios";
// // import "../../assets/css/register.css";

// // function RegisterForm() {
// //   const [formValues, setFormValues] = useState({
// //     name: "",
// //     email: "",
// //     mobile: "",
// //     gender: "",
// //     location: "",
// //     message: "",
// //     course: "",
// //   });

// //   const [errors, setErrors] = useState({});

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormValues((prevState) => ({
// //       ...prevState,
// //       [name]: value,
// //     }));
// //   };

// //   const handleSendMessage = async () => {
// //     try {
// //       const response = await axios.post(
// //         "http://192.168.1.241:5000/api/send-email",
// //         formValues,
// //         {
// //           headers: {
// //             "Content-Type": "application/json",
// //           },
// //         }
// //       );
// //       return response;
// //     } catch (error) {
// //       throw error;
// //     }
// //   };

// //   const validateForm = () => {
// //     const newErrors = {};
// //     if (!formValues.name) newErrors.name = "Name is required";
// //     if (!formValues.email) newErrors.email = "Email is required";
// //     else if (!/\S+@\S+\.\S+/.test(formValues.email))
// //       newErrors.email = "Email address is invalid";
// //     if (!formValues.mobile) newErrors.mobile = "Mobile number is required";
// //     else if (!/^\d{10}$/.test(formValues.mobile))
// //       newErrors.mobile = "Mobile number must be 10 digits";
// //     if (!formValues.gender) newErrors.gender = "Gender is required";
// //     if (!formValues.location) newErrors.location = "Location is required";
// //     if (!formValues.message) newErrors.message = "Message is required";
// //     if (!formValues.course) newErrors.course = "Course selection is required";
// //     return newErrors;
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     const formErrors = validateForm();
// //     if (Object.keys(formErrors).length === 0) {
// //       try {
// //         const response = await handleSendMessage();
// //         alert(response.data.message);
// //       } catch (error) {
// //         console.error("There was an error sending the message!", error);
// //         alert("Failed to send message");
// //       }
// //     } else {
// //       setErrors(formErrors);
// //     }
// //   };

// //   return (
// //     <>
// //       <div
// //         className="backgroundCommenImage mb-5"
// //         style={{
// //           backgroundImage: `url(${require("../../assets/images/Register/reg_banner.jpg")})`,
// //           backgroundSize: "cover",
// //           backgroundPosition: "center",
// //         }}
// //       >
// //         <div className="backgroundcommenImageBannerText">
// //           <h1 className="text-warning text-center py-5">Register</h1>
// //         </div>
// //       </div>

// //       <div className="container-fluid">
// //         <p className="text-warning text-center mb-0 py-3 h1">
// //           Explore Our Exciting Courses!
// //         </p>
// //       </div>

// //       <div className="container mt-5 mb-5">
// //         <div className="row">
// //           <div className="col-lg-6">
// //             <div className="mt-5">
// //               <div className="card register_imageSection">
// //                 <img
// //                   src={require("../../assets/images/Register/form img 1.jpg")}
// //                   alt="Register"
// //                   style={{ height: "400px" }}
// //                 />
// //               </div>
// //             </div>

// //             <div className="p-1 rounded mt-3 register_column_text">
// //               <p className="text-dark">
// //                 At Scimitar Hindustan, we offer exciting industrial automation
// //                 and digital marketing courses for college students to gain
// //                 valuable knowledge and hands-on experience in various domains
// //                 like PLC Programming Fundamentals, Process Control and
// //                 Optimization, Robotics Integration, and more. Our courses
// //                 provide students with the opportunity to work on real-world
// //                 projects, collaborate with industry professionals, and develop
// //                 essential skills for their future careers.
// //               </p>
// //             </div>
// //           </div>

// //           <div className="col-lg-6">
// //             <div className="card register_form_card p-5 bg-success text-white">
// //               <form className="Register_form_tag" onSubmit={handleSubmit}>
// //                 <div className="mb-3">
// //                   <label htmlFor="name" className="form-label">
// //                     Name:
// //                   </label>
// //                   <input
// //                     id="name"
// //                     name="name"
// //                     className="form-control"
// //                     type="text"
// //                     placeholder="Enter your name"
// //                     value={formValues.name}
// //                     onChange={handleChange}
// //                   />
// //                   {errors.name && (
// //                     <div className="text-warning">{errors.name}</div>
// //                   )}
// //                 </div>
// //                 <div className="mb-3">
// //                   <label htmlFor="email" className="form-label">
// //                     Email Address:
// //                   </label>
// //                   <input
// //                     id="email"
// //                     name="email"
// //                     className="form-control"
// //                     type="email"
// //                     placeholder="Enter your email address"
// //                     value={formValues.email}
// //                     onChange={handleChange}
// //                   />
// //                   {errors.email && (
// //                     <div className="text-warning">{errors.email}</div>
// //                   )}
// //                 </div>
// //                 <div className="mb-3 row">
// //                   <div className="col-lg-6">
// //                     <label htmlFor="mobile" className="form-label">
// //                       Mobile:
// //                     </label>
// //                     <input
// //                       id="mobile"
// //                       name="mobile"
// //                       className="form-control"
// //                       type="tel"
// //                       placeholder="Enter your mobile number"
// //                       value={formValues.mobile}
// //                       onChange={handleChange}
// //                     />
// //                     {errors.mobile && (
// //                       <div className="text-warning">{errors.mobile}</div>
// //                     )}
// //                   </div>
// //                   <div className="col-lg-6">
// //                     <label htmlFor="gender" className="form-label">
// //                       Gender:
// //                     </label>
// //                     <select
// //                       id="gender"
// //                       name="gender"
// //                       className="form-select"
// //                       value={formValues.gender}
// //                       onChange={handleChange}
// //                     >
// //                       <option value="">Select Gender</option>
// //                       <option value="male">Male</option>
// //                       <option value="female">Female</option>
// //                       <option value="other">Other</option>
// //                     </select>
// //                     {errors.gender && (
// //                       <div className="text-warning">{errors.gender}</div>
// //                     )}
// //                   </div>
// //                 </div>
// //                 <div className="mb-3">
// //                   <label htmlFor="course" className="form-label">
// //                     Choose Course:
// //                   </label>
// //                   <select
// //                     id="course"
// //                     name="course"
// //                     className="form-select"
// //                     value={formValues.course}
// //                     onChange={handleChange}
// //                   >
// //                     <option value="">Select Course</option>
// //                     <option value="Introduction to Automation">
// //                       Introduction to Automation
// //                     </option>
// //                     <option value="PLC Programming Fundamentals">
// //                       PLC Programming Fundamentals
// //                     </option>
// //                     <option value="Sensors and Actuators">
// //                       Sensors and Actuators
// //                     </option>
// //                     <option value="Advanced PLC Programming">
// //                       Advanced PLC Programming
// //                     </option>
// //                     <option value="SCADA Systems and Data Acquisition">
// //                       SCADA Systems and Data Acquisition
// //                     </option>
// //                     <option value="Robotics Integration">
// //                       Robotics Integration
// //                     </option>
// //                     <option value="Industrial Networking and Communication">
// //                       Industrial Networking and Communication
// //                     </option>
// //                     <option value="Process Control and Optimization">
// //                       Process Control and Optimization
// //                     </option>
// //                     <option value="Automation System Design">
// //                       Automation System Design
// //                     </option>
// //                   </select>
// //                   {errors.course && (
// //                     <div className="text-warning">{errors.course}</div>
// //                   )}
// //                 </div>
// //                 <div className="mb-3">
// //                   <label htmlFor="location" className="form-label">
// //                     Location:
// //                   </label>
// //                   <input
// //                     id="location"
// //                     name="location"
// //                     className="form-control"
// //                     type="text"
// //                     placeholder="Enter your location"
// //                     value={formValues.location}
// //                     onChange={handleChange}
// //                   />
// //                   {errors.location && (
// //                     <div className="text-warning">{errors.location}</div>
// //                   )}
// //                 </div>
// //                 <div className="mb-3">
// //                   <label htmlFor="message" className="form-label">
// //                     Message:
// //                   </label>
// //                   <textarea
// //                     id="message"
// //                     name="message"
// //                     className="form-control"
// //                     rows="2"
// //                     placeholder="Enter your message"
// //                     value={formValues.message}
// //                     onChange={handleChange}
// //                   ></textarea>
// //                   {errors.message && (
// //                     <div className="text-warning">{errors.message}</div>
// //                   )}
// //                 </div>
// //                 <div className="text-center">
// //                   <button type="submit" className="btn btn-warning">
// //                     Submit
// //                   </button>
// //                 </div>
// //               </form>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // }

// // export default RegisterForm;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "../../assets/css/register.css";

// function RegisterForm() {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 2000); // Simulate loading time

//     return () => clearTimeout(timer); // Cleanup the timer on component unmount
//   }, []);

//   const [formValues, setFormValues] = useState({
//     name: "",
//     email: "",
//     mobile: "",
//     gender: "",
//     location: "",
//     message: "",
//     course: "",
//   });

//   const [errors, setErrors] = useState({});
//   const [submittedData, setSubmittedData] = useState(null); // New state for showing submitted details

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleSendMessage = async () => {
//     try {
//       const response = await axios.post(
//         "http://192.168.1.241:5000/api/send-email",
//         formValues,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   };

//   const validateForm = () => {
//     const newErrors = {};
//     if (!formValues.name) newErrors.name = "Name is required";
//     if (!formValues.email) newErrors.email = "Email is required";
//     else if (!/\S+@\S+\.\S+/.test(formValues.email))
//       newErrors.email = "Email address is invalid";
//     if (!formValues.mobile) newErrors.mobile = "Mobile number is required";
//     else if (!/^\d{10}$/.test(formValues.mobile))
//       newErrors.mobile = "Mobile number must be 10 digits";
//     if (!formValues.gender) newErrors.gender = "Gender is required";
//     if (!formValues.location) newErrors.location = "Location is required";
//     if (!formValues.message) newErrors.message = "Message is required";
//     if (!formValues.course) newErrors.course = "Course selection is required";
//     return newErrors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formErrors = validateForm();
//     if (Object.keys(formErrors).length === 0) {
//       try {
//         const response = await handleSendMessage();
//         alert(response.data.message);
//         setSubmittedData(formValues); // Store submitted data to show after form clears
//         setFormValues({
//           // Reset form values
//           name: "",
//           email: "",
//           mobile: "",
//           gender: "",
//           location: "",
//           message: "",
//           course: "",
//         });
//         setErrors({});
//       } catch (error) {
//         console.error("There was an error sending the message!", error);
//         alert("Failed to send message");
//       }
//     } else {
//       setErrors(formErrors);
//     }
//   };

//   return (
//     <>
//       {/* preloader start */}

//       <div className="preloader">
//         <img src="../../assets/images/preloader.gif" alt="Loading..." />
//       </div>
//       {/* preloader end */}

//       <div
//         className="backgroundCommenImage mb-5"
//         style={{
//           backgroundImage: `url(${require("../../assets/images/Register/reg_banner.jpg")})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//         }}
//       >
//         <div className="backgroundcommenImageBannerText">
//           <h1 className="text-warning text-center py-5">Register</h1>
//         </div>
//       </div>

//       <div className="container-fluid">
//         <p className="text-warning text-center mb-0 py-3 h1">
//           Explore Our Exciting Courses!
//         </p>
//       </div>

//       <div className="container mt-5 mb-5">
//         <div className="row">
//           <div className="col-lg-6">
//             <div className="mt-5">
//               <div className="card register_imageSection">
//                 <img
//                   src={require("../../assets/images/Register/form img 1.jpg")}
//                   alt="Register"
//                   style={{ height: "400px" }}
//                 />
//               </div>
//             </div>

//             <div className="p-1 rounded register_column_text">
//               <p className="text-dark">
//                 At Scimitar Hindustan, we offer exciting industrial automation
//                 and digital marketing courses for college students to gain
//                 valuable knowledge and hands-on experience in various domains
//                 like PLC Programming Fundamentals, Process Control and
//                 Optimization, Robotics Integration, and more. Our courses
//                 provide students with the opportunity to work on real-world
//                 projects, collaborate with industry professionals, and develop
//                 essential skills for their future careers.
//               </p>
//             </div>
//           </div>

//           <div className="col-lg-6">
//             <div className="card register_form_card p-5 bg-success text-white">
//               <form className="Register_form_tag" onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label htmlFor="name" className="form-label">
//                     Name:
//                   </label>
//                   <input
//                     id="name"
//                     name="name"
//                     className="form-control"
//                     type="text"
//                     placeholder="Enter your name"
//                     value={formValues.name}
//                     onChange={handleChange}
//                   />
//                   {errors.name && (
//                     <div className="text-warning">{errors.name}</div>
//                   )}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="email" className="form-label">
//                     Email Address:
//                   </label>
//                   <input
//                     id="email"
//                     name="email"
//                     className="form-control"
//                     type="email"
//                     placeholder="Enter your email address"
//                     value={formValues.email}
//                     onChange={handleChange}
//                   />
//                   {errors.email && (
//                     <div className="text-warning">{errors.email}</div>
//                   )}
//                 </div>
//                 <div className="mb-3 row">
//                   <div className="col-lg-6">
//                     <label htmlFor="mobile" className="form-label">
//                       Mobile:
//                     </label>
//                     <input
//                       id="mobile"
//                       name="mobile"
//                       className="form-control"
//                       type="tel"
//                       placeholder="Enter your mobile number"
//                       value={formValues.mobile}
//                       onChange={handleChange}
//                     />
//                     {errors.mobile && (
//                       <div className="text-warning">{errors.mobile}</div>
//                     )}
//                   </div>
//                   <div className="col-lg-6">
//                     <label htmlFor="gender" className="form-label">
//                       Gender:
//                     </label>
//                     <select
//                       id="gender"
//                       name="gender"
//                       className="form-select"
//                       value={formValues.gender}
//                       onChange={handleChange}
//                     >
//                       <option value="">Select Gender</option>
//                       <option value="male">Male</option>
//                       <option value="female">Female</option>
//                       <option value="other">Other</option>
//                     </select>
//                     {errors.gender && (
//                       <div className="text-warning">{errors.gender}</div>
//                     )}
//                   </div>
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="course" className="form-label">
//                     Choose Course:
//                   </label>
//                   <select
//                     id="course"
//                     name="course"
//                     className="form-select"
//                     value={formValues.course}
//                     onChange={handleChange}
//                   >
//                     <option value="">Select Course</option>
//                     <option value="Introduction to Automation">
//                       Introduction to Automation
//                     </option>
//                     <option value="PLC Programming Fundamentals">
//                       PLC Programming Fundamentals
//                     </option>
//                     <option value="Sensors and Actuators">
//                       Sensors and Actuators
//                     </option>
//                     <option value="Advanced PLC Programming">
//                       Advanced PLC Programming
//                     </option>
//                     <option value="SCADA Systems and Data Acquisition">
//                       SCADA Systems and Data Acquisition
//                     </option>
//                     <option value="Robotics Integration">
//                       Robotics Integration
//                     </option>
//                     <option value="Industrial Networking and Communication">
//                       Industrial Networking and Communication
//                     </option>
//                     <option value="Process Control and Optimization">
//                       Process Control and Optimization
//                     </option>
//                     <option value="Automation System Design">
//                       Automation System Design
//                     </option>
//                   </select>
//                   {errors.course && (
//                     <div className="text-warning">{errors.course}</div>
//                   )}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="location" className="form-label">
//                     Location:
//                   </label>
//                   <input
//                     id="location"
//                     name="location"
//                     className="form-control"
//                     type="text"
//                     placeholder="Enter your location"
//                     value={formValues.location}
//                     onChange={handleChange}
//                   />
//                   {errors.location && (
//                     <div className="text-warning">{errors.location}</div>
//                   )}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="message" className="form-label">
//                     Message:
//                   </label>
//                   <textarea
//                     id="message"
//                     name="message"
//                     className="form-control"
//                     rows="2"
//                     placeholder="Enter your message"
//                     value={formValues.message}
//                     onChange={handleChange}
//                   ></textarea>
//                   {errors.message && (
//                     <div className="text-warning">{errors.message}</div>
//                   )}
//                 </div>
//                 <div className="text-center">
//                   <button type="submit" className="btn btn-warning">
//                     Submit
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default RegisterForm;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../assets/css/register.css";

function RegisterForm() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobile: "",
    gender: "",
    location: "",
    message: "",
    course: "",
  });

  const [errors, setErrors] = useState({});
  const [submittedData, setSubmittedData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSendMessage = async () => {
    try {
      const response = await axios.post(
        "http://192.168.1.241:5000/api/send-email",
        formValues,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.name) newErrors.name = "Name is required";
    if (!formValues.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formValues.email))
      newErrors.email = "Email address is invalid";
    if (!formValues.mobile) newErrors.mobile = "Mobile number is required";
    else if (!/^\d{10}$/.test(formValues.mobile))
      newErrors.mobile = "Mobile number must be 10 digits";
    if (!formValues.gender) newErrors.gender = "Gender is required";
    if (!formValues.location) newErrors.location = "Location is required";
    if (!formValues.message) newErrors.message = "Message is required";
    if (!formValues.course) newErrors.course = "Course selection is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await handleSendMessage();
        alert(response.data.message);
        setSubmittedData(formValues);
        setFormValues({
          name: "",
          email: "",
          mobile: "",
          gender: "",
          location: "",
          message: "",
          course: "",
        });
        setErrors({});
      } catch (error) {
        console.error("There was an error sending the message!", error);
        alert("Failed to send message");
      }
    } else {
      setErrors(formErrors);
    }
  };

  // if (loading) {
  //   return (
  //     <div className="preloader">
  //       <img
  //         src={require("../../assets/images/preloader.gif")}
  //         alt="Loading..."
  //       />
  //     </div>
  //   );
  // }

  return (
    <>
      <div
        className="backgroundCommenImage mb-5"
        style={{
          backgroundImage: `url(${require("../../assets/images/Register/reg_banner.jpg")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="backgroundcommenImageBannerText">
          <h1 className="text-warning text-center py-5">Register</h1>
        </div>
      </div>

      <div className="container-fluid">
        <p className="text-warning text-center mb-0 py-3 h1">
          Explore Our Exciting Courses!
        </p>
      </div>

      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="mt-5">
              <div className="card register_imageSection">
                <img
                  src={require("../../assets/images/Register/form img 1.jpg")}
                  alt="Register"
                  style={{ height: "400px" }}
                />
              </div>
            </div>

            <div className="p-1 rounded register_column_text">
              <p className="text-dark">
                At Scimitar Hindustan, we offer exciting industrial automation
                and digital marketing courses for college students to gain
                valuable knowledge and hands-on experience in various domains
                like PLC Programming Fundamentals, Process Control and
                Optimization, Robotics Integration, and more. Our courses
                provide students with the opportunity to work on real-world
                projects, collaborate with industry professionals, and develop
                essential skills for their future careers.
              </p>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card register_form_card p-5 bg-success text-white">
              <form className="Register_form_tag" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name:
                  </label>
                  <input
                    id="name"
                    name="name"
                    className="form-control"
                    type="text"
                    placeholder="Enter your name"
                    value={formValues.name}
                    onChange={handleChange}
                  />
                  {errors.name && (
                    <div className="text-warning">{errors.name}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email Address:
                  </label>
                  <input
                    id="email"
                    name="email"
                    className="form-control"
                    type="email"
                    placeholder="Enter your email address"
                    value={formValues.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="text-warning">{errors.email}</div>
                  )}
                </div>
                <div className="mb-3 row">
                  <div className="col-lg-6">
                    <label htmlFor="mobile" className="form-label">
                      Mobile:
                    </label>
                    <input
                      id="mobile"
                      name="mobile"
                      className="form-control"
                      type="tel"
                      placeholder="Enter your mobile number"
                      value={formValues.mobile}
                      onChange={handleChange}
                    />
                    {errors.mobile && (
                      <div className="text-warning">{errors.mobile}</div>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="gender" className="form-label">
                      Gender:
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      className="form-select"
                      value={formValues.gender}
                      onChange={handleChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    {errors.gender && (
                      <div className="text-warning">{errors.gender}</div>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="course" className="form-label">
                    Choose Course:
                  </label>
                  <select
                    id="course"
                    name="course"
                    className="form-select"
                    value={formValues.course}
                    onChange={handleChange}
                  >
                    <option value="">Select Course</option>
                    <option value="Introduction to Automation">
                      Introduction to Automation
                    </option>
                    <option value="PLC Programming Fundamentals">
                      PLC Programming Fundamentals
                    </option>
                    <option value="Sensors and Actuators">
                      Sensors and Actuators
                    </option>
                    <option value="Advanced PLC Programming">
                      Advanced PLC Programming
                    </option>
                    <option value="SCADA Systems and Data Acquisition">
                      SCADA Systems and Data Acquisition
                    </option>
                    <option value="Robotics Integration">
                      Robotics Integration
                    </option>
                    <option value="Industrial Networking and Communication">
                      Industrial Networking and Communication
                    </option>
                    <option value="Process Control and Optimization">
                      Process Control and Optimization
                    </option>
                    <option value="Automation System Design">
                      Automation System Design
                    </option>
                  </select>
                  {errors.course && (
                    <div className="text-warning">{errors.course}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="location" className="form-label">
                    Location:
                  </label>
                  <input
                    id="location"
                    name="location"
                    className="form-control"
                    type="text"
                    placeholder="Enter your location"
                    value={formValues.location}
                    onChange={handleChange}
                  />
                  {errors.location && (
                    <div className="text-warning">{errors.location}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message:
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="form-control"
                    rows="2"
                    placeholder="Enter your message"
                    value={formValues.message}
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && (
                    <div className="text-warning">{errors.message}</div>
                  )}
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-warning">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterForm;
