import "../../assets/css/career.css";

function Career() {
  return (
    <>
      <div
        className="backgroundCommenImage mb-5"
        style={{
          backgroundImage: `url(${require("../../assets/images/Bannar/b5.jpg")})`,
        }}
      >
        <div className="backgroundcommenImageBannerText">
          <h1 className="text-warning">Career</h1>
        </div>
      </div>

      {/*  */}
      <div className="container">
        <h1 className="text-center text-warning">
          Are You Ready To Join Our Team?
        </h1>
      </div>

      {/*  */}
      <div className="container mb-5">
        <div className="row card career_card">
          <div className="col-lg-12">
            <div className="row career_bg">
              <div className="col-lg-10 p-3">
                <h4>Senior Motion Designer</h4>
                <p className="mb-1">
                  <i className="fa-solid fa-building icon"></i> Company:{" "}
                  <strong>Milton</strong>
                </p>
              </div>
              <div className="col-lg-2 Career_apply_Box">
                <button className="btn btn-warning  Career_apply_Btn">
                  Apply Now
                </button>
              </div>
            </div>
            <div className="row p-3">
              <div className="col-lg-4 ">
                <p className="mb-1">
                  <i className="fa-solid fa-location-pin icon"></i> Location:{" "}
                  <strong>United States</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="mb-1">
                  <i className="fa-solid fa-users icon"></i> No of vacancies:{" "}
                  <strong>2</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="mb-1">
                  <i className="fa-solid fa-calendar-days icon"></i> Deadline:{" "}
                  <strong>11-01-2011</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="container mb-5">
        <div className="row card career_card">
          <div className="col-lg-12">
            <div className="row career_bg">
              <div className="col-lg-10 p-3">
                <h4>UX Researcher</h4>
                <p className="mb-1">
                  <i className="fa-solid fa-building icon"></i> Company:{" "}
                  <strong>Milton</strong>
                </p>
              </div>
              <div className="col-lg-2 Career_apply_Box">
                <button className="btn btn-warning Career_apply_Btn">
                  Apply Now
                </button>
              </div>
            </div>
            <div className="row p-3">
              <div className="col-lg-4 ">
                <p className="mb-1">
                  <i className="fa-solid fa-location-pin icon"></i> Location:{" "}
                  <strong>United States</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="mb-1">
                  <i className="fa-solid fa-users icon"></i> No of vacancies:{" "}
                  <strong>2</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="mb-1">
                  <i className="fa-solid fa-calendar-days icon"></i> Deadline:{" "}
                  <strong>11-01-2011</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="container mb-5">
        <div className="row card career_card">
          <div className="col-lg-12">
            <div className="row career_bg">
              <div className="col-lg-10 p-3">
                <h4>Cyber Intelligence Analyst</h4>
                <p className="mb-1">
                  <i className="fa-solid fa-building icon"></i> Company:{" "}
                  <strong>Milton</strong>
                </p>
              </div>
              <div className="col-lg-2 Career_apply_Box">
                <button className="btn btn-warning Career_apply_Btn">
                  Apply Now
                </button>
              </div>
            </div>
            <div className="row p-3">
              <div className="col-lg-4 ">
                <p className="mb-1">
                  <i className="fa-solid fa-location-pin icon"></i> Location:{" "}
                  <strong>United States</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="mb-1">
                  <i className="fa-solid fa-users icon"></i> No of vacancies:{" "}
                  <strong>2</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="mb-1">
                  <i className="fa-solid fa-calendar-days icon"></i> Deadline:{" "}
                  <strong>11-01-2011</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="container mb-5">
        <div className="row card career_card">
          <div className="col-lg-12">
            <div className="row career_bg ">
              <div className="col-lg-10 p-3">
                <h4>Junior Analyst- Cyber Intelligence</h4>
                <p className="mb-1">
                  <i className="fa-solid fa-building icon"></i> Company:{" "}
                  <strong>Milton</strong>
                </p>
              </div>
              <div className="col-lg-2 Career_apply_Box">
                <button className="btn btn-warning Career_apply_Btn">
                  Apply Now
                </button>
              </div>
            </div>
            <div className="row p-3">
              <div className="col-lg-4 ">
                <p className="mb-1">
                  <i className="fa-solid fa-location-pin icon"></i> Location:{" "}
                  <strong>United States</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="mb-1">
                  <i className="fa-solid fa-users icon"></i> No of vacancies:{" "}
                  <strong>2</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="mb-1">
                  <i className="fa-solid fa-calendar-days icon"></i> Deadline:{" "}
                  <strong>11-01-2011</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Career;
