import { hasFormSubmit } from "@testing-library/user-event/dist/utils";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/css/navbar.css";
import { NavLink, useLocation } from "react-router-dom";

// import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

function Navbars() {
  const [showPopUp, setShowPopUp] = useState(false);
  const [activeRoute, setActiveRoute] = useState("/");
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopUp(true);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer); // Cleanup timer if the component is unmounted
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };

  function handleRegistor() {
    // setShowPopUp(true);
    navigate("/register");
  }

  function handleCancel() {
    setShowPopUp(false);
    // navigate("/register");
  }

  const location = useLocation();

  const getActiveClass = (path) => {
    return location.pathname === path ? "text-warning" : "text-dark";
  };

  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
    <>
      <div className="top-bar-area headerbg text-light">
        <div className="container ">
          <div className="row align-items-center">
            {/* <!-- Address Info --> */}
            <div className="col-lg-8 col-md-12 tabview navbarhead">
              <div className="d-flex align-items-center me-lg-5 mb-2 mb-lg-0 addressIcon">
                <h6>
                  <i className="fas fa-map pt-4 me-2"></i>Salem, Erode
                </h6>
              </div>
              <div className="d-flex align-items-center me-lg-4 mb-2 mb-lg-0">
                <h6>
                  <i className="fas fa-envelope-open pt-4 me-2"></i>
                  scimitarhindustan@gmail.com
                </h6>
              </div>
              <div className="d-flex align-items-center">
                <h6>
                  <i className="fas fa-phone pt-4 me-2 mx-3"></i>+91- 812 231
                  7080
                </h6>
              </div>
            </div>
            {/* <!-- User Login Links --> */}
            <div className="col-lg-4 col-md-5 text-lg-end text-center fs-1 hideicon ">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-facebook mx-3 h5"></i>
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-instagram mx-3 h5"></i>
              </a>
              <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-youtube mx-3 h5"></i>
              </a>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-linkedin mx-3 h5"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <NavLink className="navbar-brand " to="#">
            <img
              className="logo"
              src={require("../../assets/images/scimitar logo slogan.png")}
              alt="Logo"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                <NavLink className="navbar-brand" to="#">
                  <img
                    className="logo"
                    src={require("../../assets/images/scimitar logo png.jpg")}
                    alt="Logo"
                  />
                </NavLink>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="navbardask navbarmoblie">
              <ul className="navbar-nav">
                {/* Home Link */}
                <li className="nav-item me-3 fs-5">
                  <button
                    className={`nav-link ${getActiveClass("/")}`}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => handleNavigation("/")}
                  >
                    Home
                  </button>
                </li>

                {/* About Us Link */}

                <li className="nav-item me-3 fs-5">
                  <button
                    className={`nav-link ${getActiveClass("/about")}`}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => handleNavigation("/about")}
                  >
                    About Us
                  </button>
                </li>

                {/* <div class="dropdown me-3 fs-5 ">
                  <a
                    className={`nav-link  commomtextcolor dropdown-toggle text-dark `}
                    href="#"
                    id="pagesDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                    Services
                  </a>

                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      {" "}
                      <button
                        className={`dropdown-item ${getActiveClass(
                          "/digitalmarketing"
                        )}`}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => handleNavigation("/digitalmarketing")}
                      >
                        Digital Marketing
                      </button>{" "}
                    </li>
                    <li>
                      {" "}
                      <button
                        className={`dropdown-item ${getActiveClass("/iat")}`}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => handleNavigation("/")}
                      >
                        Industrial Automation
                      </button>
                    </li>
                  </ul>
                </div> */}

                <div class="dropdown me-3 fs-5 ">
                  <a
                    class="btn btn-white dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className={`nav-link  commomtextcolor dropdown-toggle text-dark `}
                    href="#"
                    id="pagesDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Courses
                  </a>

                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                      {" "}
                      <button
                        className={`dropdown-item ${getActiveClass("/iat")}`}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => handleNavigation("/courses")}
                      >
                        Industrial Automation
                      </button>
                    </li>
                    <li>
                      {" "}
                      <button
                        className={`dropdown-item ${getActiveClass(
                          "/digitalmarketing"
                        )}`}
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                        onClick={() => handleNavigation("/digitalmarketing")}
                      >
                        Digital Marketing
                      </button>{" "}
                    </li>
                  </ul>
                </div>

                {/* Courses */}
                <li className="nav-item me-3 fs-5">
                  <button
                    className={`nav-link ${getActiveClass("/courses")}`}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => handleNavigation("/services")}
                  >
                    Services
                  </button>
                </li>

                {/* Blog Link */}

                <li className="nav-item me-3 fs-5">
                  <button
                    className={`nav-link ${getActiveClass("/blog")}`}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => handleNavigation("/career")}
                  >
                    Career
                  </button>
                </li>

                {/* Contact Link */}

                <li className="nav-item me-3 fs-5">
                  <button
                    className={`nav-link ${getActiveClass("/contact")}`}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => handleNavigation("/contact")}
                  >
                    Contact
                  </button>
                </li>
                <li>
                  <Link
                    to="/register"
                    onClick={handleRegistor}
                    className="btn btn-warning text-black me-3"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <i className="fas fa-edit me-1"></i> Register
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* <!-- Start Register Form 
      
    ============================================= --> */}

      <Modal
        className="popup-model w-100"
        show={showPopUp}
        onHide={handleCancel}
      >
        <Modal.Body>
          {/* Registration Form */}
          <div className="reg-area bg-gray">
            <div className="container">
              <div className="row bg-white">
                <div className="reg-items">
                  <div className="col-lg-12 ">
                    <div>
                      <div className="row">
                        <div className="col-lg-10 site-heading">
                          <h2>Get a Free Online Registration</h2>
                        </div>
                        <div className="col-lg-2 d-flex justify-content-end registerclose">
                          <button
                            className="btn text-end bg-white"
                            onClick={handleCancel}
                            aria-label="Close"
                          >
                            <i className="fa-solid fa-xmark text-danger"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              placeholder="Name"
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              placeholder="Phone"
                              type="text"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              placeholder="Email*"
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 reg-form pop_up_drop">
                          <div className="form-group ">
                            <select
                              name="subject"
                              className="choose_course "
                              value={formData.subject}
                              onChange={handleChange}
                            >
                              <option value="">Choose Courses</option>
                              <option value="1">
                                Introduction to Automation
                              </option>
                              <option value="2">
                                PLC Programming Fundamentals
                              </option>
                              <option value="3">Sensors and Actuators</option>
                              <option value="4">
                                Advanced PLC Programming
                              </option>
                              <option value="5">
                                SCADA Systems and Data Acquisition
                              </option>
                              <option value="6">Robotics Integration</option>
                              <option value="7">
                                Industrial Networking and Communication
                              </option>
                              <option value="8">
                                Process Control and Optimization
                              </option>
                              <option value="9">
                                Automation System Design
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12 reg-form pop_up_drop2">
                          <div className="form-group ">
                            <select
                              name="subject"
                              className="choose_course"
                              value={formData.subject}
                              onChange={handleChange}
                            >
                              <option value="">Prefered Location</option>
                              <option value="Salem">Salem</option>
                              <option value="Erode">Erode</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              placeholder="Message"
                              name="message"
                              value={formData.message}
                              onChange={handleChange}
                              rows="1"
                            />
                          </div>
                        </div>
                        <div className="col-md-12 text-center">
                          <button
                            type="submit"
                            className="btn btn-warning paddingbtn "
                          >
                            Register Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <!-- End Register Form -->*/}
    </>
  );
}
export default Navbars;
