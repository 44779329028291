import "../../assets/css/digital.css";
// import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from "react";
import data from "../Apiurl/meta_api.js";
import preloader from "../../assets/images/preloader.gif";

function DigitalMarketing() {
  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    const serviceMeta = data[0]?.service_page;
    if (serviceMeta) {
      setMeta(serviceMeta);
    }
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // if (loading) {
  //   return (
  //     <div className="preloader">
  //       <img src={preloader} alt="Loading..." />
  //     </div>
  //   );
  // }

  return (
    <>
      <Helmet>
        <title>{meta ? meta.title : "Digital Marketing"}</title>
      </Helmet>{" "}
      <div
        className="backgroundCommenImage mb-5"
        style={{
          backgroundImage: `url(${require("../../assets/images/Bannar/digitalmarketingbannar.jpg")})`,
        }}
      >
        {" "}
        <div className="backgroundcommenImageBannerText">
          <h1 className="text-warning">Digital Marketing</h1>
        </div>
      </div>
      <div className="container Digital_marketing_section ">
        <div className="row">
          <div className="col-lg-7">
            <div className="row">
              <div className="col-lg-7">
                <div className="">
                  <img
                    className="Marketingimagfirst"
                    src={require("../../assets/images/300103.jpg")}
                  ></img>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="h-50"></div>
                <div>
                  {/* <img className='Marketingimagsecond ' src={require("../../assets/images/2_4.jpg")}></img> */}
                </div>
              </div>
            </div>
            <div className="row main_digital">
              <div className="col-lg-3"></div>
              <div className="col-lg-9">
                <img
                  className="Marketingimagthird"
                  src={require("../../assets/images/Services/Digital Marketing/dm1.jpg")}
                ></img>
              </div>
            </div>
          </div>
          <div className="col-lg-5  margintopdigital" data-aos="fade-up">
            <div className="">
              <h1 className="commomtextcolor">Digital Marketing</h1>
              <h3 className="text-warning">
                Master the Art of Digital Marketing
              </h3>
            </div>
            <div>
              <p className="marketing_text  commonPages">
                In the fast-paced world of digital marketing, staying ahead
                requires more than just basic knowledge. Our comprehensive
                Digital Marketing Training Program is designed to equip you with
                the skills, strategies, and insights needed to excel in todays
                digital landscape. Whether youre a beginner looking to start a
                career in digital marketing or a professional aiming to sharpen
                your skills, our training offers something for everyone.
              </p>
              {/* <button className='btn btn-warning knowmoreservice'>Know More</button> */}
            </div>
          </div>
        </div>
      </div>
      <h1 className=" digitalwhy text-center commomtextcolor margintopdigitalcom ">
        Why Choose Our Training Program
      </h1>
      <div className="row mt-5 p-5">
        <div className="col-lg-6" data-aos="fade-left">
          <div className="container Expert_led">
            <h3 className="text-warning">Expert-Led Training</h3>
            <p className="marketing_text">
              Learn from industry leaders with years of experience in digital
              marketing. Our instructors are seasoned professionals who bring
              real-world insights, case studies, and proven techniques to the
              classroom. You'll gain hands-on experience with the tools and
              strategies used by top marketers worldwide.
            </p>
            <div className="mt-5">
              <h6>
                <i
                  className="fas fa-arrow-right text-warning mx-2"
                  style={{ fontSize: "20px" }}
                ></i>{" "}
                Introduction to Expert-Led Training: Overview and benefits.
              </h6>
              <h6>
                <i
                  className="fas fa-arrow-right text-warning mt-2 mx-2 "
                  style={{ fontSize: "20px" }}
                ></i>{" "}
                Designing Effective Training Programs: Key elements and
                strategies.
              </h6>
              <h6>
                <i
                  className="fas fa-arrow-right text-warning mt-2 mx-2"
                  style={{ fontSize: "20px" }}
                ></i>{" "}
                Choosing the Right Experts: Criteria and best practices.
              </h6>
              <h6>
                <i
                  className="fas fa-arrow-right text-warning mt-2 mx-2"
                  style={{ fontSize: "20px" }}
                ></i>{" "}
                Implementing Expert-Led Training: Steps and considerations.
              </h6>
              <h6>
                <i
                  className="fas fa-arrow-right text-warning mt-2 mx-2"
                  style={{ fontSize: "20px" }}
                ></i>{" "}
                Evaluating the Impact of Training: Metrics and assessment
                methods.
              </h6>
            </div>
          </div>
        </div>
        <div className="col-lg-6" data-aos="fade-right">
          <img
            className="Training_img "
            src={require("../../assets/images/Services/Digital Marketing/why choose our.jpg")}
          ></img>
        </div>
      </div>
      <div className="container my-5">
        <h1 className="DigitalComprehensive  text-center commomtextcolor ">
          Comprehensive Curriculum
        </h1>
        <div className="row mt-5">
          <div className="col-lg-4 col-ld-12 " data-aos="fade-right">
            <div className="row">
              <div className="row Comprehensiveicon">
                <div className="col-lg-2 text-warning  ">
                  <i class="fa-brands fa-searchengin iconDgm card p-2 commomtextcolor h6  "></i>
                </div>
                <div className="col-lg-10">
                  <h4 className="text-warning">
                    Search Engine Optimization (SEO){" "}
                  </h4>
                  <p className="marketing_text">
                    Master the art of optimizing content for search engines to
                    increase visibility and organic traffic.{" "}
                  </p>
                </div>
              </div>
              <div className="row Comprehensiveicon ">
                <div className="col-lg-2 text-warning ">
                  <i class="fa-solid fa-file-signature iconDgm  card p-2 commomtextcolor "></i>
                </div>
                <div className="col-lg-10">
                  <h4 className="text-warning">
                    Pay-Per-Click (PPC) Advertising{" "}
                  </h4>
                  <p className="marketing_text">
                    Learn how to create, manage,and optimize in the PPC
                    campaigns for maximum ROI.{" "}
                  </p>
                </div>
              </div>
              <div className="row Comprehensiveicon ">
                <div className="col-lg-2 text-warning  ">
                  <i class="fa-solid fa-bullhorn iconDgm  card p-2 commomtextcolor "></i>
                </div>

                <div className="col-lg-10">
                  <h4 className="text-warning">Social Media Marketing </h4>
                  <p className="marketing_text">
                    Building a strong brand presence, and driving sales on
                    platforms like Facebook, like Instagram, LinkedIn, and
                    Twitter in involves a strategic approach{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 hover-zoomimg" data-aos="zoom-in">
            <img
              className="bg-image hover-zoom"
              src={require("../../assets/images/Services/Digital Marketing/center.jpg")}
              alt="Descriptive Alt Text"
            />
          </div>

          <div className="col-lg-4 col-md-12" data-aos="fade-left">
            <div className="row mx-2">
              <div className="row Comprehensiveicon">
                <div className="col-lg-2  text-warning ">
                  <i class="fa-brands fa-blogger-b iconDgmLeft  card p-2 commomtextcolor   "></i>
                </div>
                <div className="col-lg-10">
                  <h4 className="text-warning mx-2">Content Marketing</h4>
                  <p className="marketing_text mx-2">
                    Develop skills to create compelling content that attracts
                    and converts your target audience.{" "}
                  </p>
                </div>
              </div>
              <div className="row Comprehensiveicon my-4">
                <div className="col-lg-2 text-warning  ">
                  <i class="fa-regular fa-envelope iconDgmLeft  card p-2 commomtextcolor  "></i>
                </div>
                <div className="col-lg-10">
                  <h4 className="text-warning mx-2">Email Marketing</h4>
                  <p className="marketing_text mx-2">
                    Understand how to build and nurture relationships through
                    effective email campaigns.{" "}
                  </p>
                </div>
              </div>
              <div className="row Comprehensiveicon">
                <div className="col-lg-2 text-warning ">
                  <i class="fa-solid fa-chart-line iconDgmLeft  card p-2 commomtextcolor "></i>
                </div>
                <div className="col-lg-10 ">
                  <h4 className="text-warning mx-2">Analytics & Reporting</h4>
                  <p className="marketing_text mx-2">
                    Learn how to measure, analyze, and report on the success of
                    the your digital marketing in efforts using tools like
                    Google Analytics.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*            
            
            <div className="row">
            <div className="DIV-2 bg-warnning ">
                <img className="scor1" src={require("../../assets/images/3_6.jpg")}></img>
                <div className="row container p-5 text-white">
                    <h4 className="commomtextcolor"> Hands-On Learning</h4><br></br>
                    <p className=" Learning" >Our training is designed to be interactive and practical. You’ll work on real-life projects, use the latest tools, and apply what you’ve learned in a simulated environment. This hands-on approach ensures you’re
                        not just learning theory, but also gaining the practical experience needed to succeed.</p>

                </div>
            </div>
            <div className="Learning_main">
                <img className="Learning_img" src={require("../../assets/images/3_6.jpg")}></img>
                <div className="row  p-3 text-white">
                    <h4 className="commomtextcolor"> Hands-On Learning</h4><br></br>
                    <p className=" text-white" >Our training is designed to be interactive and practical. You’ll work on real-life projects, use the latest tools, and apply what you’ve learned in a simulated environment. This hands-on approach ensures you’re
                        not just learning theory, but also gaining the practical experience needed to succeed.</p>

                </div>
            </div>
           
                <div className="col-lg-12 certificationbackground">
                    <div className="row  Certification">
                        <div className="col-lg-6 col-sm-12">
                            <p className="text-warning Certificationhead "><h4 className="text-warning">Certification</h4></p><br></br>
                            <p className="text-white Certificationhead ">Upon completion of the course, you’ll receive a certification that is recognized by employers worldwide. This certification demonstrates your proficiency in digital marketing and can help you stand out in the job market or advance in your current role.</p>

                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <img className="scor2" src={require("../../assets/images/certificate.jpg")}></img>

                        </div>
                    </div>
                </div>
            </div> */}
      {/* Hands-On Learning start */}
      <section class="about-section section-padding py-5" id="section_2">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-6 col-12 mb-4 mb-lg-0 d-flex align-items-center"
              data-aos="fade-down"
            >
              <div class="services-info">
                <h1 class="text-white mb-4  ">Hands-On Learning</h1>

                <p class="text-white ">
                  Our training is designed to be interactive and practical.
                  You’ll work on real-life projects, use the latest tools, and
                  apply what you’ve learned in a simulated environment. This
                  hands-on approach ensures you’re not just learning theory, but
                  also gaining the practical experience needed to succeed.
                </p>
                <div className="mt-5">
                  <h6 className="text-white">
                    <i
                      className="fas fa-arrow-right text-warning mx-2"
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Expert-Led Training
                  </h6>
                  <h6 className="text-white">
                    <i
                      className="fas fa-arrow-right text-warning mt-2 mx-2"
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Comprehensive Curriculum
                  </h6>
                  <h6 className="text-white">
                    <i
                      className="fas fa-arrow-right text-warning mt-2 mx-2"
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Hands-On Learning
                  </h6>
                  <h6 className="text-white">
                    <i
                      className="fas fa-arrow-right text-warning mt-2 mx-2"
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Certification
                  </h6>
                  <h6 className="text-white">
                    <i
                      className="fas fa-arrow-right text-warning mt-2 mx-2"
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Flexible Learning Options
                  </h6>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-12" data-aos="fade-up">
              <div class="about-text-wrap">
                <img
                  className="about-image img-fluid"
                  src={require("../../assets/images/Services/Digital Marketing/sect 1 left.jpg")}
                ></img>
                <div class="about-text-info d-flex ">
                  <div class="d-flex">
                    <i class="about-text-icon bi-person"></i>
                  </div>
                  <div class="ms-5 ">
                    <h3>A Happy Moment</h3>

                    <p class="mb-0">your amazing festival experience with us</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Hands-On Learning end */}
      {/* certificate start */}
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-6" data-aos="fade-right">
            <h1 className=" Certification_text h1">Certification</h1>
            <div className="">
              <p className="marketing_text">
                Upon completion of the course, you’ll receive a certification
                that is recognized by employers worldwide. This certification
                demonstrates your proficiency in digital marketing and can help
                you stand out in the job market or advance in your current role.
              </p>
            </div>

            <div className="">
              <p className="Certification_para">
                <span>
                  <i class="fa-solid fa-circle mx-2 Certification_icon"></i>
                </span>
                Completing our course doesn't just equip you with in-depth
                knowledge and practical skills—it also earns you a certification
                that is respected and recognized by employers worldwide.{" "}
              </p>
            </div>

            <div className="">
              <p className="Certification_para">
                <span>
                  <i class="fa-solid fa-circle mx-2 Certification_icon"></i>
                </span>
                Additionally, as the digital marketing industry continues to
                evolve, having a certification that is up-to-date and aligned
                with industry standards demonstrates your commitment to
                continuous learning and professional growth.
              </p>
            </div>

            {/* <div className="">
                            <h3 className="Certification_para"><span><i class="fa-solid fa-circle mx-2"></i></span>Upon completion of the course, you’ll receive a certification that is recognized by employers worldwide. This certification demonstrates your proficiency in digital marketing and can help you stand out in the job market or advance in your current role.</h3>
                        </div> */}
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div
                className="col-lg-6 certification_column"
                data-aos="fade-down"
              >
                <img
                  className="certification_image1 "
                  src={require("../../assets/images/Services/Digital Marketing/certf2.jpg")}
                ></img>
              </div>
              <div className="col-lg-6" data-aos="fade-up">
                <img
                  className="certification_image2"
                  src={require("../../assets/images/Services/Digital Marketing/certf1.jpg")}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* certificate end */}
      {/* Flexible Learning Options start */}
      <div id="description" class="basic-2 py-5">
        <div class="container">
          <div class="row mb-4">
            <div class="col-lg-12 text-center">
              <h2 className="text-warning">Flexible Learning Options</h2>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-lg-6" data-aos="fade-right">
              <ul class="list-unstyled">
                <li class="media mb-4">
                  <div className="container card_Flexible_Learning  ">
                    <div className="row">
                      <div className="col-lg-2">
                        <div
                          className="card Card_Flexible_Learning bg-success"
                          data-aos="fade-down"
                        >
                          <i class="text-warning d-flex justify-content-center h3 ">
                            1
                          </i>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <div class="media-body mx-3">
                          <h4 className="backgroundPerson">
                            In-Person Classes
                          </h4>
                          <p className="marketing_text">
                            Access the training materials from anywhere, at any
                            time, with our self-paced online courses.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="media mb-4">
                  <div className="container card_Flexible_Learning  ">
                    <div className="row">
                      <div className="col-lg-2">
                        <div
                          className="card Card_Flexible_Learning bg-success"
                          data-aos="fade-down"
                        >
                          <i class="text-warning d-flex justify-content-center h3 ">
                            2
                          </i>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <div class="media-body mx-3">
                          <h4 className="backgroundPerson">Online Courses</h4>
                          <p className="marketing_text">
                            Access the training materials from anywhere, at any
                            time, with our self-paced online courses.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="media mb-4">
                  <div className="container card_Flexible_Learning ">
                    <div className="row">
                      <div className="col-lg-2">
                        <div
                          className="card Card_Flexible_Learning bg-success"
                          data-aos="fade-down"
                        >
                          <i class="text-warning d-flex justify-content-center h3 ">
                            3
                          </i>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <div class="media-body mx-3">
                          <h4 className="backgroundPerson">Live Webinars</h4>
                          <p className="marketing_text">
                            Participate in live, instructor-led webinars that
                            allow you to ask questions and get real-time
                            feedback.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-lg-6" data-aos="fade-left">
              <ul class="list-unstyled">
                <li class="media mb-4">
                  <div className="container card_Flexible_Learning">
                    <div className="row">
                      <div className="col-lg-2">
                        <div
                          className="card Card_Flexible_Learning bg-success"
                          data-aos="fade-up"
                        >
                          <i class="text-warning d-flex justify-content-center h3 ">
                            4
                          </i>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <div class="media-body mx-3">
                          <h4 className="backgroundPerson">
                            Aspiring Digital Marketers
                          </h4>
                          <p className="marketing_text">
                            Beginners who want to start a career in digital
                            marketing.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="media mb-4">
                  <div className="container card_Flexible_Learning">
                    <div className="row">
                      <div className="col-lg-2">
                        <div
                          className="card Card_Flexible_Learning bg-success"
                          data-aos="fade-up"
                        >
                          <i class="text-warning d-flex justify-content-center h3 ">
                            5
                          </i>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <div class="media-body mx-3">
                          <h4 className="backgroundPerson">
                            Marketing Professionals
                          </h4>
                          <p className="marketing_text">
                            Individuals looking to upskill and stay current with
                            the latest digital marketing trends.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="media mb-4">
                  <div className="container card_Flexible_Learning">
                    <div className="row">
                      <div className="col-lg-2">
                        <div
                          className="card Card_Flexible_Learning bg-success"
                          data-aos="fade-up"
                        >
                          <i class="text-warning d-flex justify-content-center h3 ">
                            6
                          </i>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <div class="media-body mx-3">
                          <h4 className="backgroundPerson">
                            Entrepreneurs & Business Owners
                          </h4>
                          <p className="marketing_text">
                            Business owners who want to effectively market their
                            products or services online.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Flexible Learning Options start */}
      {/* what is your gain */}
      <div className="container mt-5 mb-5 ">
        <div className="row   ">
          <div className="">
            {/* <!-- Start Single Item --> */}
            <div className="item">
              <div className="row align-items-center">
                <div className="col-lg-5" data-aos="fade-down">
                  <img
                    className="whatyougain"
                    src={require("../../assets/images/Services/Digital Marketing/what u will gain.jpg")}
                  />
                </div>
                <div className="col-lg-7 mb-4 mt-5 " data-aos="fade-up">
                  <h2>What You’ll Gain</h2>
                  <h3 className="text-warning">
                    By the end of the program, you’ll have
                  </h3>
                  <p className="">
                    A Curated Skill Set v Expertise in niche areas that are
                    critical to your industry’s future.
                  </p>
                  <h3>Learning outcomes</h3>
                  <ul>
                    <li>
                      <i class="fa-regular fa-circle-check text-warning"></i>
                      <span className="mx-2">
                        A solid understanding of digital marketing fundamentals
                        and advanced techniques.
                      </span>
                    </li>
                    <li>
                      <i class="fa-regular fa-circle-check text-warning"></i>
                      <span className="mx-2">
                        Practical experience with digital marketing tools and
                        platforms..
                      </span>
                    </li>
                    <li>
                      <i class="fa-regular fa-circle-check text-warning"></i>
                      <span className="mx-2">
                        The ability to create and execute successful digital
                        marketing campaigns.
                      </span>
                    </li>
                    <li>
                      <i class="fa-regular fa-circle-check text-warning"></i>
                      <span className="mx-2">
                        Insights into the latest trends and best practices in
                        digital marketing.
                      </span>
                    </li>
                    <li>
                      <i class="fa-regular fa-circle-check text-warning"></i>
                      <span className="mx-2">
                        A professional network of peers and industry experts.
                      </span>
                    </li>
                    <li>
                      <i class="fa-regular fa-circle-check text-warning"></i>
                      <span className="mx-2">
                        A recognized certification to boost your career.
                      </span>
                    </li>
                  </ul>
                  <div className="bottom-info align-left"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Single Item -->*}}
            {/* what is your gain */}
    </>
  );
}

export default DigitalMarketing;
