import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import data from "../Apiurl/meta_api";
import { Helmet } from "react-helmet";
import preloader from "../../assets/images/preloader.gif";
import "../../assets/css/contact.css";

function Contact() {
  const [meta, setMeta] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState("branch1");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      const contactMeta = data[0]?.contact_page;
      if (contactMeta) {
        setMeta(contactMeta);
      }
    };

    fetchData();

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    AOS.init({
      duration: 2000,
      once: true,
    });

    return () => clearTimeout(timer);
  }, []); // Dependency array to ensure it runs only once

  const handleBranchClick = (branch) => {
    setSelectedBranch(branch);
  };

  // if (loading) {
  //   return (
  //     <div className="preloader">
  //       <img src={preloader} alt="Loading..." />
  //     </div>
  //   );
  // }

  return (
    <>
      <Helmet>
        <title>{meta ? meta.title : "Contact Us"}</title>
      </Helmet>

      <div
        className="backgroundCommenImage mb-5"
        style={{
          backgroundImage: `url(${require("../../assets/images/Bannar/banner1.jpg")})`,
        }}
      >
        <div className="backgroundcommenImageBannerText">
          <h1 className="text-warning">Contact</h1>
        </div>
      </div>

      <div className="container p-4 commonPages">
        <h5 style={{ textAlign: "center" }}>
          We’d love to hear from you! Whether you have a question, feedback, or
          just want to say hello, please use the form below or get in touch
          through one of our other channels.
        </h5>
      </div>

      <div className="container mt-3 mb-5">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="text-warning">Our Branches</h2>
            <div className="row">
              {/* Branch 1 */}
              <div className="col-lg-6 col-md-6" data-aos="fade-right">
                <p
                  className="card callBox p-3"
                  onClick={() => handleBranchClick("branch1")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="row commonPages">
                    <div className="col-lg-12">
                      <h4 className="text-dark">Branch 1</h4>
                    </div>
                    <div className="col-lg-2 col-md-1">
                      <i className="fa-solid fa-envelope commomtextcolor fs-6 mx-2 text-success mt-2"></i>
                    </div>
                    <div className="col-lg-10 col-md-11">info@example.com</div>
                    <div className="col-lg-2 col-md-1">
                      <i className="fa-solid fa-phone commomtextcolor fs-6 mx-2 text-success mt-2"></i>
                    </div>
                    <div className="col-lg-10 col-md-11">
                      +1 (555) 123-4567
                      <br />
                    </div>
                    <div className="col-lg-2 col-md-1">
                      <i className="fa-solid fa-location-dot fs-6 mx-2 text-success mt-2"></i>
                    </div>
                    <div className="col-lg-10 col-md-11">
                      123 Example St, Suite 456, City, State, ZIP
                    </div>
                  </div>
                </p>
              </div>
              {/* Branch 2 */}
              <div className="col-lg-6 col-md-6" data-aos="fade-left">
                <p
                  className="card callBox p-3"
                  onClick={() => handleBranchClick("branch2")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="row commonPages">
                    <div className="col-lg-12">
                      <h4 className="text-dark">Branch 2</h4>
                    </div>
                    <div className="col-lg-2 col-md-1">
                      <i className="fa-solid fa-envelope commomtextcolor fs-6 mx-2 text-success mt-2"></i>
                    </div>
                    <div className="col-lg-10 col-md-11">info@example.com</div>
                    <div className="col-lg-2 col-md-1">
                      <i className="fa-solid fa-phone commomtextcolor fs-6 mx-2 text-success mt-2"></i>
                    </div>
                    <div className="col-lg-10 col-md-11">
                      +1 (555) 123-4567
                      <br />
                    </div>
                    <div className="col-lg-2 col-md-1">
                      <i className="fa-solid fa-location-dot fs-6 mx-2 text-success mt-2"></i>
                    </div>
                    <div className="col-lg-10 col-md-11">
                      123 Example St, Suite 456, City, State, ZIP
                    </div>
                  </div>
                </p>
              </div>
              {/* Map */}
              <div className="col-lg-12 mt-4" data-aos="fade-right">
                {selectedBranch === "branch1" && (
                  <iframe
                    src="https://www.google.com/maps/embed?pb=...branch1"
                    width="100%"
                    height="250"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                )}
                {selectedBranch === "branch2" && (
                  <iframe
                    src="https://www.google.com/maps/embed?pb=...branch2"
                    width="100%"
                    height="250"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                )}
              </div>
            </div>
          </div>
          {/* Contact Form */}
          <div
            className="col-lg-5 mb-5 card contact_sectionPage text-center"
            data-aos="fade-right"
          >
            <h2 className="mb-5 text-warning mt-2">Contact Us</h2>
            <div>
              <input
                className="form-control contact_formControls"
                placeholder="Name*"
              />
            </div>
            <div>
              <input
                className="form-control mt-3 contact_formControls"
                placeholder="Email*"
              />
            </div>
            <div>
              <input
                className="form-control mt-3 contact_formControls"
                placeholder="Phone*"
              />
            </div>
            <div>
              <textarea
                className="form-control w-100 mt-3"
                style={{ height: "180px", paddingBottom: "100px" }}
                placeholder="Message*"
              ></textarea>
            </div>
            <div className="infocontact text-center p-2">
              <button className="btn btn-success mt-3">SEND MESSAGE</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
