import React, { useEffect } from "react";
import Connections from "./components/website/Connection";
import Footer from "./components/website/Footer";
import Navbars from "./components/website/Navbar";
import Home from "./components/website/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/website/About";
import DigitalMarketing from "./components/website/DigitalMarketing";
import Blog from "./components/website/Blog";
import Contact from "./components/website/Contact";
import IndustrialAutomation from "./components/website/IndustrialAutomation";
import ScrollToTop from "./components/website/ScrollTop";
import BlogPageOne from "./components/website/BlogPageOne";
import RegisterForm from "./components/website/RegisterForm";
// import { motion } from "framer-motion";
// import axios from "axios";

// In src/index.js or src/App.js (or another top-level file)
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Career from "./components/website/Career";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbars />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/digitalmarketing" element={<DigitalMarketing />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/iat" element={<IndustrialAutomation />} />
          <Route path="/courses" element={<IndustrialAutomation />} />
          <Route path="/header" element={<Connections />} />
          {/* <Route path="/blogfull" element={<BlogPageOne />} /> */}
          <Route path="/career" element={<Career />} />

          <Route path="/register" element={<RegisterForm />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
