import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/aboutAs.css";
import Carousel from "./Carousel";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Button } from "react-bootstrap";
import "../../assets/css/commen.css";
// import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import React, { useState, useEffect } from "react";
import data from "../Apiurl/meta_api.js";
import preloader from "../../assets/images/preloader.gif";

function About() {
  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const fetchData = () => {
    const aboutMeta = data[0]?.about_page;
    if (aboutMeta) {
      setMeta(aboutMeta);
    }
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  // if (loading) {
  //   return (
  //     <div className="preloader">
  //       <img src={preloader} alt="Loading..." />
  //     </div>
  //   );
  // }

  return (
    <>
      <Helmet>
        <title>{meta ? meta.title : "About"}</title>
      </Helmet>

      <div
        className="backgroundCommenImage mb-1"
        style={{
          backgroundImage: `url(${require("../../assets/images/About Us/aboutscimitar.png")})`,
        }}
      >
        {" "}
        <div className="backgroundcommenImageBannerText">
          <h1 className="text-warning">About Us</h1>
        </div>
      </div>
      <div className="container-fluid">
        {/*Who We Are Start */}

        <div className="row  d-flex pt-5 who">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="  main_text" data-aos="fade-right">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1 className="text-warning">At Scimitar Hindustan</h1>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                {/* <h3 className=" pt-1" style={{ color: "rgb(17,58,42)" }}>
                  {" "}
                  At Scimitar Hindustan{" "}
                </h3> */}
                <p className=" commonPages pt-3">
                  {" "}
                  At Scimitar Hindustan, we are committed to empowering
                  individuals and businesses through innovative training
                  programs in Industrial Automation and Digital Marketing. Our
                  goal is to provide holistic learning solutions that equip you
                  with the skills and knowledge needed to excel in today’s
                  competitive landscape. Our expert team is made up of seasoned
                  professionals with extensive experience in both industrial
                  automation technologies and digital marketing strategies.
                  Whether you’re looking to master automation systems like PLC,
                  SCADA, and HMI, or gain expertise in SEO, social media, and
                  online marketing, we offer comprehensive courses designed to
                  meet the diverse needs of modern professionals.
                </p>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 pt-3">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {" "}
                    <h3>
                      <p className="h2 text-warning">
                        <i class="fa-solid fa-calendar-check"></i>{" "}
                      </p>{" "}
                      Years of Expertise
                    </h3>
                    <p className="">
                      With over 3 years of experience in the industry, we have
                      trained countless professionals.
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {" "}
                    <h3>
                      <p className="h2 text-warning">
                        <i class="fa-solid fa-calendar-check"></i>{" "}
                      </p>{" "}
                      Hands-on Training{" "}
                    </h3>{" "}
                    <p>
                      We believe in practical, hands-on learning, whether you
                      are mastering PLC, SCADA systems, HMI, or honing skills
                    </p>
                  </div>

                  {/* <div className="col-lg-12 col-md-12 col-sm-6 pt-3">
                    {" "}
                    <h3>
                      <p className="h2 text-warning">
                        <i class="fa-solid fa-calendar-check"></i>{" "}
                      </p>{" "}
                      State-of-the-Art Facilities
                    </h3>
                    <p>+01234567890</p>
                  </div> */}

                  {/* <div className="col-lg-6 col-md-6 col-sm-12 pt-3 Discover">
                    <button className="btn btn-success rounded-5 Discover_but">
                      Discover Now
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 pt-5">
            <div className="row"></div>
            <div className=" col-lg-12" data-aos="fade-left">
              <img
                className="bg-warning Scimitar"
                src={require("../../assets/images/About Us/Who_we_are.png")}
              ></img>
            </div>
            {/* <div className=' col-lg-12 '><img className='Scimitarimg' src={require("../../assets/images/img-2.png")}></img>
                        </div> */}
          </div>
        </div>
      </div>

      {/* Who We Are end*/}

      {/*Vision Start*/}
      <div className="container-fuild  mt-5">
        <div className="row pb-5 mt-5 mb-5 ">
          <div className=" col-lg-12 ">
            <div className="row">
              <div className="col-lg-12  ">
                <h3 className="abouthead "></h3>
              </div>

              <div
                className="col-lg-6 col-md-6  col-sm-12 mt-4"
                data-aos="fade-up"
              >
                <img
                  className="aboutimage"
                  src={require("../../assets/images/About Us/vision 2.jpg")}
                ></img>
                <div className="aboutred"></div>
              </div>

              <div
                className="col-lg-5 col-md-6 col-sm-12 vision_main"
                data-aos="fade-down"
              >
                <div className="col-lg-12  ">
                  {" "}
                  <h1 className="pt-4 text-warning">Our Vision</h1>
                </div>
                <p className=" commonPages p-2">
                  At Scimitar Hindustan, our vision is to become the leading
                  provider of Industrial Automation and Digital Marketing
                  training, empowering individuals and businesses with the
                  knowledge and skills to excel in the digital and automated
                  future. We strive to deliver world-class training programs
                  that combine practical experience with cutting-edge
                  technology, ensuring our students and professionals gain the
                  expertise needed to succeed in their careers and industries.
                  Our goal is to shape the future workforce, driving innovation
                  and growth through exceptional training and continuous
                  learning.
                </p>
                {/* <div className='knowmore_main'> <button className='btn btn-warning knowmore'>Know More</button></div> */}
                <div className="mt-4 ">
                  <h6 className="">
                    <i
                      className="fas fa-arrow-right text-warning mx-2"
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Vision for Leadership
                  </h6>
                  <h6 className="">
                    <i
                      className="fas fa-arrow-right text-warning  mx-2"
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Empowering Individuals and Businesses
                  </h6>
                  <h6 className="">
                    <i
                      className="fas fa-arrow-right text-warning mx-2"
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    World-Class Training Programs
                  </h6>
                  <h6 className="">
                    <i
                      className="fas fa-arrow-right text-warning  mx-2"
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Expertise and Career Success
                  </h6>
                  <h6 className="">
                    <i
                      className="fas fa-arrow-right text-warning mx-2"
                      style={{ fontSize: "20px" }}
                    ></i>{" "}
                    Shaping the Future Workforce
                  </h6>
                </div>
              </div>

              <div className="col-lg-1 "></div>
            </div>
          </div>
        </div>
      </div>

      {/*Mission End*/}
      <div className="wcs-area headerbg text-light Mission commonmargin pt-5 pb-5 ">
        <div className="container pb-5 mb-5">
          <div className="row">
            {/* <div className="col-lg-6 thumb bg-cover" style="background-image: url(images/16.jpg);"></div> */}
            <div className="col-lg-12 content">
              <div className="site-heading text-left" data-aos="fade-down">
                <h1 className="text-warning">Our Mission</h1>
                <p className="commonPages">
                  "At Scimitar Hindustan is to provide high-quality, hands-on
                  training in Industrial Automation and Digital Marketing,
                  equipping individuals and businesses with the tools and
                  knowledge they need to thrive in an ever-evolving
                  technological landscape. We are committed to delivering
                  practical, industry-relevant education through expert-led
                  courses, state-of-the-art facilities, and customized learning
                  solutions. By fostering innovation, technical expertise, and
                  strategic thinking, we aim to empower our learners to achieve
                  professional excellence and drive success in their respective
                  fields. "
                </p>
              </div>
              <hr />
            </div>
            <div
              className="col-lg-6 col-md-6 border-right"
              data-aos="fade-right"
            >
              {/* <!-- item --> */}
              <div className="item">
                <div className="icon">
                  <i class="fa-solid fa-user-tie"></i>
                </div>
                <div className="info">
                  <h3>
                    <a href="#">Empower Businesses </a>
                  </h3>
                  <p className="commonPages ">
                    Provide cutting-edge digital marketing strategies and IT
                    solutions that drive business growth and enhance online
                    visibility.
                  </p>
                </div>
              </div>
              {/* <!-- item --> */}

              {/* <!-- item --> */}
              <div className="item">
                <div className="icon">
                  <i class="fa-solid fa-lightbulb"></i>
                </div>
                <div className="info">
                  <h3>
                    <a href="#">Innovate Continuously</a>
                  </h3>
                  <p className="commonPages ">
                    Stay ahead of industry trends and technological advancements
                    to deliver state-of-the-art solutions
                  </p>
                </div>
              </div>
              {/* <!-- item --> */}

              {/* <!-- item --> */}
              <div className="item">
                <div className="icon">
                  <i class="fa-solid fa-calendar-check"></i>{" "}
                </div>
                <div className="info">
                  <h3>
                    <a href="#">Ensure Excellence</a>
                  </h3>
                  <p className="commonPages ">
                    Maintain high standards of quality and reliability in all
                    our services to ensure client satisfaction and success.
                  </p>
                </div>
              </div>
              {/* <!-- item --> */}
            </div>
            <div className="col-lg-6 col-md-6" data-aos="fade-left">
              <hr className="Partnerships"></hr>
              <div className="item">
                <div className="icon">
                  <i class="fa-solid fa-handshake"></i>
                </div>
                <div className="info">
                  <h3>
                    <a href="#">Build Partnerships </a>
                  </h3>
                  <p className="commonPages ">
                    Foster strong, collaborative relationships with our clients,
                    understanding their unique needs and delivering personalized
                    solutions.{" "}
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="icon">
                  <i class="fa-solid fa-fingerprint"></i>
                </div>
                <div className="info">
                  <h3>
                    <a href="#">Promote Security & Efficiency</a>
                  </h3>
                  <p className="commonPages ">
                    Implement robust IT solutions that ensure data security,
                    operational efficiency, and seamless business processes.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Why Chose Us -->*/}

      {/*  start About Testimonal */}

      {/* <div className='row pb-5'>
                    <div className='col-lg-4'>
                        <div className='card missioncard '>
                            <img className="missionimage" src={require("../../assets/images/2_4.jpg")}></img>
                            <h1>afuh</h1>
                            <div>
                                <p className='missiontestimonal'>Provide cutting-edge digital marketing strategies and IT solutions that drive business
                                    growth and enhance online visibility.</p>
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card missioncard '>
                            <img className="missionimage" src={require("../../assets/images/2_4.jpg")}></img>
                            <h1>afuh</h1>
                            <div>
                                <p className='missiontestimonal'>Provide cutting-edge digital marketing strategies and IT solutions that drive business
                                    growth and enhance online visibility.</p>
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card missioncard '>
                            <img className="missionimage" src={require("../../assets/images/2_4.jpg")}></img>
                            <h1>afuh</h1>
                            <div>
                                <p className='missiontestimonal'>Provide cutting-edge digital marketing strategies and IT solutions that drive business
                                    growth and enhance online visibility.</p>
                            </div>
                        </div>

                    </div>


                </div> */}
      {/*  start About Testimonal */}

      <hr />

      {/* <div className='card pt-5 pb-5 mt-5 mb-5 w-50 ml-5 p-5 bottoms'>
        <div className='row'>
            <div className='col-lg-4'><img  className="footerimage" src={require("../../assets/images/1_1.jpg")} alt="Image 1"></img></div>
            <div className='col-lg-4'><img  className="footerimage" src={require("../../assets/images/1_2.jpg")} alt="Image 2"></img></div>
            <div className='col-lg-4'><img  className="footerimage" src={require("../../assets/images/1_3.jpg")} alt="Image 3"></img></div>
        </div>
        <h5 className='bottomstext hidden-text'>
            "" We are dedicated to transforming our clients'
            digital and technological landscapes, helping them navigate and excel in an ever-evolving marketplace ""
        </h5>
    </div> */}

      <div className="container commonmargin mb-5 pb-5 ">
        <div className="row  d-flex pt-5  ">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card  whoare" data-aos="fade-up">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1 className="text-warning ">
                  Empowering Digital Transformation ?
                </h1>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h3 className=" col-lg-12 h4 Navigating commomtextcolor ">
                  Navigating and Excelling in a Dynamic Marketplace
                </h3>

                <p className="commonPages ">
                  We are dedicated to transforming our clients' digital and
                  technological landscapes,elping them navigate and excel in an
                  ever-evolving marketplace{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="row"></div>
            <div className=" col-lg-12" data-aos="fade-down">
              <img
                className="Transformation"
                src={require("../../assets/images/About Us/About_Empowering.jpg")}
              ></img>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='row  d-flex pt-5 '>

                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='pt-5 card mt-5 p-5 whoare '>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <h2 className='text-warning abouthead'>Who Are We ?</h2>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <p className='adoutPasage '>
                                    At Scimitar Hindustan Pvt ltd, we are dedicated to empowering businesses through innovative digital solutions.
                                    ensuring that we provide holistic solutions to help your business thrive.
                                    Our team comprises seasoned professionals with expertise in both digital marketing and IT services,
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='row'>
                        </div>
                        <div className=' col-lg-12 '><img style={{ marginLeft: "-100px", height: "500px", width: "600px", objectFit: "cover" }} src={require("../../assets/images/9.jpg")}></img>
                        </div>
                    </div>
                </div> */}
    </>
  );
}
export default About;
