import Header from "./Header";
function Connections(){
    return(
        <>
        <Header/>
        
        </>
    )

}
export default Connections;