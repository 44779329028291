import React, { useState, useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../assets/css/iat.css";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import data from "../Apiurl/meta_api.js";
// import preloader from "../../assets/images/preloader.gif";
import { Carousel } from "bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import logo1 from "../../assets/images/Services/IAT/company_logo_1.png";
import logo2 from "../../assets/images/Services/IAT/company_logo_2.png";
import logo3 from "../../assets/images/Services/IAT/company_logo_3.png";
import logo4 from "../../assets/images/Services/IAT/company_logo_4.png";
import logo5 from "../../assets/images/Services/IAT/company_logo_5.png";
import logo6 from "../../assets/images/Services/IAT/company_logo_6.png";

function IndustrialAutomation() {
  const carouselRef = useRef(null);

  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [selectedCourse, setSelectedCourse] = useState("beginner");

  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopUp(true);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer); // Cleanup timer if the component is unmounted
  }, []);

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const fetchData = () => {
    const servicetMeta = data[0]?.service_page; // Make sure 'data' is defined
    if (servicetMeta) {
      setMeta(servicetMeta);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };

  const handleRegistor = () => {
    setShowPopUp(true);
    // navigate("/register");
  };

  const handleCancel = () => {
    setShowPopUp(false);
    // navigate("/register");
  };

  const handleBeginner = () => setSelectedCourse("beginner");
  const handleIntermediate = () => setSelectedCourse("intermediate");
  const handleAdvance = () => setSelectedCourse("advance");

  // if (loading) {
  //   return (
  //     <div className="preloader">
  //       <img src={preloader} alt="Loading..." />
  //     </div>
  //   );
  // }

  return (
    <>
      <Helmet>
        <title>{meta ? meta.title : "Service"}</title>
      </Helmet>

      {/* <!-- Start Register Form 
      
    ============================================= --> */}
      {/* <Modal
        className="popup-model w-100"
        show={showPopUp}
        onHide={handleCancel}
      >
        <Modal.Body>
          <div className="reg-area bg-gray">
            <div className="container">
              <div className="row bg-white">
                <div className="reg-items">
                  <div className="col-lg-12 ">
                    <div>
                      <div className="row">
                        <div className="col-lg-10 site-heading">
                          <h2>Get a Free Online Registration</h2>
                        </div>
                        <div className="col-lg-2 d-flex justify-content-end registerclose">
                          <button
                            className="btn text-end bg-white"
                            onClick={handleCancel}
                            aria-label="Close"
                          >
                            <i className="fa-solid fa-xmark text-danger"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              placeholder="Name"
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              placeholder="Phone"
                              type="text"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              placeholder="Email*"
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 reg-form pop_up_drop">
                          <div className="form-group ">
                            <select
                              name="subject"
                              className="choose_course "
                              value={formData.subject}
                              onChange={handleChange}
                            >
                              <option value="">Choose Courses</option>
                              <option value="1">
                                Introduction to Automation
                              </option>
                              <option value="2">
                                PLC Programming Fundamentals
                              </option>
                              <option value="3">Sensors and Actuators</option>
                              <option value="4">
                                Advanced PLC Programming
                              </option>
                              <option value="5">
                                SCADA Systems and Data Acquisition
                              </option>
                              <option value="6">Robotics Integration</option>
                              <option value="7">
                                Industrial Networking and Communication
                              </option>
                              <option value="8">
                                Process Control and Optimization
                              </option>
                              <option value="9">
                                Automation System Design
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12 reg-form pop_up_drop2">
                          <div className="form-group ">
                            <select
                              name="subject"
                              className="choose_course"
                              value={formData.subject}
                              onChange={handleChange}
                            >
                              <option value="">Prefered Location</option>
                              <option value="Salem">Salem</option>
                              <option value="Erode">Erode</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              placeholder="Message"
                              name="message"
                              value={formData.message}
                              onChange={handleChange}
                              rows="2"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <Button type="submit" className="paddingbtn">
                            Register Now
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* <!-- End Register Form -->*/}

      <div
        className="backgroundCommenImage mb-5 "
        style={{
          backgroundImage: `url(${require("../../assets/images/Services/IAT/Course_Banner.jpg")})`,
        }}
      >
        {" "}
        <div className="backgroundcommenImageBannerText">
          <h1 className="text-warning">Courses</h1>
        </div>
      </div>

      {/* industrial automation training */}
      <div className="container Pioneers_img  commonmargin ">
        <h1 className="commomtextcolor text-center text-warning">
          {" "}
          Industrial Automation Training
        </h1>
        <div className="row ">
          <div className="col-lg-6 ">
            <div className="row Pioneers_img">
              <div className="col-lg-2 mt-5">
                {/* <img className='Pioneersfirst_img mt-5' src={require("../../assets/images/pinorees2.png.jpg")}></img> */}
              </div>
              <div className="col-lg-10" data-aos="fade-up">
                <img
                  className="Pioneerssecond_img"
                  src={require("../../assets/images/Services/IAT/Our_Training_Programs_2.png")}
                ></img>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 Pioneers_text commonmargin"
            data-aos="fade-right"
          >
            {/* <h1 className="commomtextcolor"> Industrial Automation Training</h1> */}
            <h3 className=" Welcome">
              Welcome to Scimitar Hindustan - Pioneers in Industrial Automation
              Training
            </h3>
            <p className=" commonPages">
              Unlock the future of industrial efficiency with our comprehensive
              automation training programs. Whether youre a beginner looking to
              enter the field or an experienced professional seeking to upgrade
              your skills, we provide the tools and knowledge to drive your
              career forward.
            </p>
            <p className="">
              <p className=" commonPages">
                Unlock the future of industrial efficiency with our
                comprehensive automation training programs. Whether youre a
                beginner looking to enter the field or an experienced
                professional seeking to upgrade your skills, we provide the
                tools and knowledge to drive your career forward.
              </p>
            </p>
            {/* <button className="btn btn-success" type="button">
              Know More
            </button> */}
            <button className="btn btn-success" type="button">
              Know More
            </button>
          </div>
        </div>
      </div>

      {/* cutting card section */}

      <div className="container">
        <div class="row">
          <div class="col-sm-4 col-lg-4 col-md-4 Cutting_Card_Section">
            <div className="card pt-5 cutting_card" data-aos="fade-right">
              <h1 className="Cutting_icons">
                <i class="fa-solid fa-chalkboard-user"></i>{" "}
              </h1>
              <h3 className="text-warning">Expert Instructors</h3>
              <p className="unlock_card p-3 commonPages">
                Learn from industry leaders with hands-on experience
              </p>
            </div>
          </div>
          <div class="col-sm-4 col-lg-4 col-md-4 Cutting_Card_Section">
            <div className="card pt-5 cutting_card" data-aos="fade-down">
              <h1 className="Cutting_icons">
                <i class="fa-solid fa-book-open-reader"></i>{" "}
              </h1>
              <h3 className="text-warning ">Cutting-Edge Training</h3>
              <p className="unlock_card p-3 commonPages">
                Stay ahead with the latest in industrial automation technologies
              </p>
            </div>
          </div>
          <div class="col-sm-4 col-lg-4 col-md-4 Cutting_Card_Section">
            <div className="card pt-5 cutting_card" data-aos="fade-left">
              <h1 className="Cutting_icons">
                <i class="fa-solid fa-award"></i>{" "}
              </h1>
              <h3 className="text-warning">Flexible Learning Options</h3>
              <p className="unlock_card p-3 commonPages">
                Choose from in-person, online, and hybrid courses
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* cutting card end */}

      {/* Our Training Programs */}

      <div className="container">
        <div className="iatHeading text-center">
          <h1 className="text-warning">Our Training Programs</h1>
          <h3 className="mt-5">
            Explore our diverse range of training programs designed to cater to
            various levels of expertise and industry needs.
          </h3>
        </div>

        <div className="row IATButton ">
          <div className="col-lg-4 Our_Training_Programmes">
            <button
              className={`${
                selectedCourse === "beginner"
                  ? "btn btn-secondary border border-secondary text-white w-100 rounded-pill IATButton"
                  : "btn btn-white border border-secondary text-secondary w-100 rounded-pill IATButton"
              }`}
              onClick={handleBeginner}
            >
              Beginner Course
            </button>
          </div>
          <div className="col-lg-4 Our_Training_Programmes">
            <button
              className={`${
                selectedCourse === "intermediate"
                  ? "btn btn-warning border border-warning text-white w-100 rounded-pill IATButton"
                  : "btn btn-white border border-warning text-warning w-100 rounded-pill IATButton"
              }`}
              onClick={handleIntermediate}
            >
              Intermediate Course
            </button>
          </div>
          <div className="col-lg-4 Our_Training_Programmes">
            <button
              className={`${
                selectedCourse === "advance"
                  ? "btn btn-success border border-success text-white w-100 rounded-pill IATButton"
                  : "btn btn-white border border-success text-success w-100 rounded-pill IATButton"
              }`}
              onClick={handleAdvance}
            >
              Advance Course
            </button>
          </div>
        </div>
        {/* beginner button */}
        {selectedCourse === "beginner" && (
          <div className="mt-5">
            <div className="row" data-aos="fade-up">
              <div className="col-lg-4 Our_Training_Programming col-md-6 col-sm-12 mt-3">
                <div className="card blogCardSection">
                  <img
                    src={require("../../assets/images/Services/IAT/automationc-1.jpg")}
                    className="ImageCardBlog1"
                    alt="Course Image"
                  />
                  <div className="row mt-4 mx-2">
                    <div className="col-lg-6">
                      <p className="blogCardDevelopment">Development</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="blogCourseAmount mx-4">$10.00</p>
                    </div>
                  </div>
                  <div>
                    <h5 className="text-warning blogCardCourseHeading mt-3">
                      Introduction to Automation
                    </h5>
                    <p className="p-2 commonPages">
                      Introduction to Automation explores the principles and
                      technologies used to control and optimize industrial
                      processes with minimal human intervention
                    </p>
                  </div>
                  <div>
                    <div className="row p-2">
                      <div className="col-lg-7">
                        <p>
                          <i className="fa-solid fa-book"></i> Duration - 3
                          months
                        </p>
                      </div>
                      <div className="col-lg-5">
                        <p>
                          <i className="fa-solid fa-clock"></i> 11h 20m
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 Our_Training_Programming col-md-6 col-sm-12 mt-3">
                <div className="card blogCardSection">
                  <img
                    src={require("../../assets/images/Services/IAT/prog_c-2.jpg")}
                    className="ImageCardBlog1"
                    alt="Course Image"
                  />
                  <div className="row mt-4 mx-2">
                    <div className="col-lg-6">
                      <p className="blogCardDevelopment">Development</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="blogCourseAmount mx-4">$10.00</p>
                    </div>
                  </div>
                  <div>
                    <h5 className="text-warning blogCardCourseHeading mt-3">
                      PLC Programming Fundamentals
                    </h5>
                    <p className="p-2 commonPages">
                      PLC Programming Fundamentals cover the basics of designing
                      and implementing control logic using Programmable Logic
                      Controllers.
                    </p>
                  </div>
                  <div>
                    <div className="row p-2">
                      <div className="col-lg-7">
                        <p>
                          <i className="fa-solid fa-book"></i> Duration - 3
                          months
                        </p>
                      </div>
                      <div className="col-lg-5">
                        <p>
                          <i className="fa-solid fa-clock"></i> 11h 20m
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 Our_Training_Programming col-md-6 col-sm-12 mt-3">
                <div className="card blogCardSection">
                  <img
                    src={require("../../assets/images/Services/IAT/adv_plc_c4.jpg")}
                    className="ImageCardBlog1"
                    alt="Course Image"
                  />
                  <div className="row mt-4 mx-2">
                    <div className="col-lg-6">
                      <p className="blogCardDevelopment">Development</p>
                    </div>
                    <div className="col-lg-6">
                      <p className="blogCourseAmount mx-4">$10.00</p>
                    </div>
                  </div>
                  <div>
                    <h5 className="text-warning blogCardCourseHeading mt-3">
                      Sensors and Actuators
                    </h5>
                    <p className="p-2 commonPages">
                      Sensors and actuators are critical components in
                      automation systems; sensors detect and measure physical
                      variables such as temperature or pressure,
                    </p>
                  </div>
                  <div>
                    <div className="row p-2">
                      <div className="col-lg-7">
                        <p>
                          <i className="fa-solid fa-book"></i> Duration - 3
                          months
                        </p>
                      </div>
                      <div className="col-lg-5">
                        <p>
                          <i className="fa-solid fa-clock"></i> 11h 20m
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* intermediate button */}

        {selectedCourse === "intermediate" && (
          // <div>Content for Intermediate Course</div>
          <div className="mt-5">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12  ">
                <div className="row">
                  <div className="col-lg-4 Our_Training_Programming col-md-6 col-sm-12 mt-3">
                    <div className="card blogCardSection">
                      <img
                        src={require("../../assets/images/Services/IAT/adv_plc_c4.jpg")}
                        className="ImageCardBlog1"
                        alt="Course Image"
                      />
                      <div className="row mt-4 mx-2">
                        <div className="col-lg-6">
                          <p className="blogCardDevelopment">Development</p>
                        </div>
                        <div className="col-lg-6">
                          <p className="blogCourseAmount mx-4">$10.00</p>
                        </div>
                      </div>
                      <div>
                        <h5 className="text-warning blogCardCourseHeading mt-3">
                          Advanced PLC Programming
                        </h5>
                        <p className="p-2 commonPages">
                          Advanced PLC Programming involves developing complex
                          control logic for programmable logic controllers to
                          handle sophisticated automation tasks.
                        </p>
                      </div>
                      <div>
                        <div className="row p-2">
                          <div className="col-lg-7">
                            <p>
                              <i className="fa-solid fa-book"></i> Duration - 3
                              months
                            </p>
                          </div>
                          <div className="col-lg-5">
                            <p>
                              <i className="fa-solid fa-clock"></i> 11h 20m
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 Our_Training_Programming col-md-6 col-sm-12 mt-3">
                    <div className="card blogCardSection">
                      <img
                        src={require("../../assets/images/Services/IAT/sca_da_data_acq_c5.jpg")}
                        className="ImageCardBlog1"
                        alt="Course Image"
                      />
                      <div className="row mt-4 mx-2">
                        <div className="col-lg-6">
                          <p className="blogCardDevelopment">Development</p>
                        </div>
                        <div className="col-lg-6">
                          <p className="blogCourseAmount mx-4">$10.00</p>
                        </div>
                      </div>
                      <div>
                        <h5 className="text-warning blogCardCourseHeading mt-3">
                          SCADA Systems and Data Acquisition
                        </h5>
                        <p className="p-2 commonPages">
                          SCADA Systems and Data Acquisition involve monitoring
                          and controlling industrial processes through real-time
                          data collection and analysis.
                        </p>
                      </div>
                      <div>
                        <div className="row p-2">
                          <div className="col-lg-7">
                            <p>
                              <i className="fa-solid fa-book"></i> Duration - 3
                              months
                            </p>
                          </div>
                          <div className="col-lg-5">
                            <p>
                              <i className="fa-solid fa-clock"></i> 11h 20m
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 Our_Training_Programming col-md-6 col-sm-12 mt-3">
                    <div className="card blogCardSection">
                      <img
                        src={require("../../assets/images/Services/IAT/robo_integ_c6.jpg")}
                        className="ImageCardBlog1"
                        alt="Course Image"
                      />
                      <div className="row mt-4 mx-2">
                        <div className="col-lg-6">
                          <p className="blogCardDevelopment">Development</p>
                        </div>
                        <div className="col-lg-6">
                          <p className="blogCourseAmount mx-4">$10.00</p>
                        </div>
                      </div>
                      <div>
                        <h5 className="text-warning blogCardCourseHeading mt-3">
                          Robotics Integration
                        </h5>
                        <p className="p-2 commonPages">
                          Robotics Integration focuses on incorporating robotic
                          systems into existing workflows to automate tasks and
                          enhance productivity.
                        </p>
                      </div>
                      <div>
                        <div className="row p-2">
                          <div className="col-lg-7">
                            <p>
                              <i className="fa-solid fa-book"></i> Duration - 3
                              months
                            </p>
                          </div>
                          <div className="col-lg-5">
                            <p>
                              <i className="fa-solid fa-clock"></i> 11h 20m
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* advance button */}

        {selectedCourse === "advance" && (
          // <div>Content for Intermediate Course</div>
          <div className="mt-5">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12  ">
                <div className="row">
                  <div className="col-lg-4 Our_Training_Programming col-md-6 col-sm-12 mt-3">
                    <div className="card blogCardSection">
                      <img
                        src={require("../../assets/images/Services/IAT/c7.jpg")}
                        className="ImageCardBlog1"
                        alt="Course Image"
                      />
                      <div className="row mt-4 mx-2">
                        <div className="col-lg-6">
                          <p className="blogCardDevelopment">Development</p>
                        </div>
                        <div className="col-lg-6">
                          <p className="blogCourseAmount mx-4">$10.00</p>
                        </div>
                      </div>
                      <div>
                        <h5 className="text-warning blogCardCourseHeading mt-3">
                          Industrial Networking and Communication
                        </h5>
                        <p className="p-2 commonPages">
                          Industrial Networking and Communication involve the
                          integration of various network technologies to enable
                          seamless data exchange
                        </p>
                      </div>
                      <div>
                        <div className="row p-2">
                          <div className="col-lg-7">
                            <p>
                              <i className="fa-solid fa-book"></i> Duration - 3
                              months
                            </p>
                          </div>
                          <div className="col-lg-5">
                            <p>
                              <i className="fa-solid fa-clock"></i> 11h 20m
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 Our_Training_Programming col-md-6 col-sm-12 mt-3">
                    <div className="card blogCardSection">
                      <img
                        src={require("../../assets/images/Services/IAT/c8.jpg")}
                        className="ImageCardBlog1"
                        alt="Course Image"
                      />
                      <div className="row mt-4 mx-2">
                        <div className="col-lg-6">
                          <p className="blogCardDevelopment">Development</p>
                        </div>
                        <div className="col-lg-6">
                          <p className="blogCourseAmount mx-4">$10.00</p>
                        </div>
                      </div>
                      <div>
                        <h5 className="text-warning blogCardCourseHeading mt-3">
                          Process Control and Optimization
                        </h5>
                        <p className="p-2 commonPages">
                          Process Control and Optimization aim to enhance the
                          efficiency and stability of industrial processes by
                          regulating variables and adjusting parameters.
                        </p>
                      </div>
                      <div>
                        <div className="row p-2">
                          <div className="col-lg-7">
                            <p>
                              <i className="fa-solid fa-book"></i> Duration - 3
                              months
                            </p>
                          </div>
                          <div className="col-lg-5">
                            <p>
                              <i className="fa-solid fa-clock"></i> 11h 20m
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 Our_Training_Programming col-md-6 col-sm-12 mt-3">
                    <div className="card blogCardSection">
                      <img
                        src={require("../../assets/images/Services/IAT/c9.jpg")}
                        className="ImageCardBlog1"
                        alt="Course Image"
                      />
                      <div className="row mt-4 mx-2">
                        <div className="col-lg-6">
                          <p className="blogCardDevelopment">Development</p>
                        </div>
                        <div className="col-lg-6">
                          <p className="blogCourseAmount mx-4">$10.00</p>
                        </div>
                      </div>
                      <div>
                        <h5 className="text-warning blogCardCourseHeading mt-3">
                          Automation System Design
                        </h5>
                        <p className="p-2 commonPages">
                          Automation System Design involves creating efficient
                          systems to perform tasks with minimal human
                          intervention.
                        </p>
                      </div>
                      <div>
                        <div className="row p-2">
                          <div className="col-lg-7">
                            <p>
                              <i className="fa-solid fa-book"></i> Duration - 3
                              months
                            </p>
                          </div>
                          <div className="col-lg-5">
                            <p>
                              <i className="fa-solid fa-clock"></i> 11h 20m
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* how to begin your automation value */}

      <div className="commonmargin">
        <div className="container begin_your_automation">
          <div className="row ">
            <div className="col-lg-6" data-aos="fade-right">
              <h3 className=" Journey text-center commomtextcolor">
                How to Begin Your Automation Journey
              </h3>
              <div className="row mt-5">
                <div className="col-lg-2 col-sm-2">
                  <h1 className="Automation_icons">
                    <i class="fa-solid fa-industry mx-4"></i>{" "}
                  </h1>
                </div>
                <div className="col-lg-10 col-sm-10">
                  <h6 className="Assess_content ">
                    <span className="Assess text-warning ">
                      Assess Your Needs :
                    </span>{" "}
                    Identify the specific requirements and goals for
                    implementing automation in your environment
                  </h6>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-2">
                  <h1 className="Automation_icons">
                    <i class="fa-solid fa-arrow-up-right-from-square mx-4"></i>
                  </h1>
                </div>
                <div className="col-lg-10">
                  <h6 className="Assess_content">
                    <span className="Assess text-warning">
                      Choose the Right Training :
                    </span>{" "}
                    Select from our courses that cover foundational to advanced
                    automation technologies
                  </h6>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-2">
                  <h1 className="Automation_icons">
                    <i class="fa-solid fa-chart-line mx-4"></i>{" "}
                  </h1>
                </div>
                <div className="col-lg-10">
                  <h6 className="Assess_content">
                    <span className="Assess text-warning">
                      Gain Practical Experience :
                    </span>{" "}
                    Engage in hands-on training to apply theoretical knowledge
                    in real-world scenarios
                  </h6>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-2">
                  <h1 className="Automation_icons">
                    <i class="fa-solid fa-building-columns mx-4"></i>{" "}
                  </h1>
                </div>
                <div className="col-lg-10">
                  <h6 className="Assess_content">
                    <span className="Assess text-warning">Stay Updated :</span>{" "}
                    Continuous learning is key to keeping up with advancements
                    in automation technologies
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5">
              <div className="row">
                <div className="col-lg-8" data-aos="fade-up">
                  <div className="Automation mt-3">
                    <img
                      className="automation_img "
                      src={require("../../assets/images/Services/IAT/how to begin 2.jpg")}
                    ></img>
                  </div>
                </div>
                <div className="col-lg-4 Automation_Journey_YellowColor">
                  <div className="bg-warning Automation_card"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Embrace the Future of Automation */}

      {/* <div className="text-center mt-5">
                <h2 className="text-warning">Embrace the Future of Automation</h2>
            </div> */}

      <div className="container commonmargin">
        <div className="row Embrace_the_Future">
          <div className="col-lg-6" data-aos="fade-up">
            <img
              src={require("../../assets/images/Services/IAT/embrace the fut 2.jpg")}
              className="Embrace_the_Future_Image"
            ></img>
          </div>

          <div className="col-lg-6 Embrace_Heading" data-aos="fade-down">
            <h2 className="text-warning">Embrace the Future of Automation</h2>

            <div className="mt-5 ">
              <p className="commonPages">
                Automation is a driving force behind modern industry, in
                enhancing the efficiency, in the precision, and safety. By
                understanding and implementing automation technologies, you can
                position yourself or your organization for success in a
                competitive landscape. Automation reduces the need for manual
                intervention by handling repetitive tasks, leading to faster
                production cycles and optimized workflows.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ'S SECTION */}
      {/* 
      <div className="text-center commonmargin pt-5 Frequently_Ask">
        <h2 className="text-warning">Frequently Asked Questions ?</h2>
      </div>

      <section className="pb-5">
        <div className="container mb-5 Frequently_Asked_container">
          <div className="row py-8 py-md-10 py-lg-11 Frequently_Asked">
            <div className="col-lg-12">
              <div
                className="accordion mt-lg-4 ps-3 pe-x1 bg-white"
                id="accordion"
              >
                <div className="accordion-item" data-aos="fade-right">
                  <h2 className="accordion-header" id="heading1">
                    <button
                      className="accordion-button fs-8 lh-lg  pt-x1 pb-2 "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse1"
                      aria-expanded="true"
                      aria-controls="collapse1"
                    >
                      What is PLC Training in Chennai?
                    </button>
                  </h2>
                  <div
                    id="collapse1"
                    className="accordion-collapse collapse show"
                    aria-labelledby="heading1"
                    data-bs-parent="#accordion"
                  >
                    <div className="accordion-body lh-xl pt-0 pb-x1">
                      <p className="commonPages">
                        For those students, who are new to the world of
                        automation, we help in getting to know what is a PLC
                        (Programmable Logic Controller) and train them on 3
                        significant areas – Computers, Interface (I/O) and PLC
                        programming. Technocrat Automation offers the PLC
                        Training in Chennai.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item" data-aos="fade-left">
                  <h2 className="accordion-header" id="heading2">
                    <button
                      className="accordion-button fs-8 lh-lg  pt-x1 pb-2 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2"
                      aria-expanded="false"
                      aria-controls="collapse2"
                    >
                      Why PLC training in Chennai is required?
                    </button>
                  </h2>
                  <div
                    id="collapse2"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2"
                    data-bs-parent="#accordion"
                  >
                    <div className="accordion-body lh-xl pt-0 pb-x1">
                      <p className="commonPages">
                        Core engineering graduates, just with the knowledge of
                        PLC basics, cannot achieve their dream career in leading
                        industries, unless they are equipped with full-fledged
                        Allen Bradley PLC training (in Chennai) or DCS training
                        (in Chennai).
                      </p>{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item" data-aos="fade-right">
                  <h2 className="accordion-header" id="heading3">
                    <button
                      className="accordion-button fs-8 lh-lg  pt-x1 pb-2 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      How do I choose the best PLC training course?
                    </button>
                  </h2>
                  <div
                    id="collapse3"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading3"
                    data-bs-parent="#accordion"
                  >
                    <div className="accordion-body lh-xl pt-0 pb-x1">
                      <p className="commonPages">
                        With a number of PLC training courses to choose from,
                        every student ends up confused. But with us, it’s easy;
                        you can customize our PLC online training modules
                        according to your needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item" data-aos="fade-left">
                  <h2 className="accordion-header" id="heading4">
                    <button
                      className="accordion-button fs-8 lh-lg  pt-x1 pb-2 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse4"
                      aria-expanded="false"
                      aria-controls="collapse4"
                    >
                      How to know if it's the best automation training in
                      chennai?
                    </button>
                  </h2>
                  <div
                    id="collapse4"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading4"
                    data-bs-parent="#accordion"
                  >
                    <div className="accordion-body lh-xl pt-0 pb-x1">
                      <p className="commonPages">
                        With hundreds of automation industries in Chennai,
                        offering job opportunities, every automation training
                        institute claims to provide the best. To know the best,
                        you can check the syllabus and training methodologies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item" data-aos="fade-right">
                  <h2 className="accordion-header" id="heading5">
                    <button
                      className="accordion-button fs-8 lh-lg pt-x1 pb-2 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse5"
                      aria-expanded="false"
                      aria-controls="collapse5"
                    >
                      What if I don't get placed after industrial automation
                      training in Chennai?
                    </button>
                  </h2>
                  <div
                    id="collapse5"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading5"
                    data-bs-parent="#accordion"
                  >
                    <div className="accordion-body lh-xl pt-0 pb-x1">
                      <p className="commonPages">
                        It’s common to have what if thoughts, especially, when
                        you are assured for automation jobs. But, you can
                        believe in Technocrat, for its strong association with
                        hundreds of industrial automation companies. Technocrat
                        Automation offers the Industrial Automation Training in
                        Chennai course.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="text-center commonmargin pt-5 Frequently_Ask">
        <h2 className="text-warning">Our Certified Partners!</h2>
      </div>

      <div
        id="sponsorCarousel"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner mb-5">
          <div className="carousel-item active" data-bs-interval="2000">
            <a href="#">
              <img src={logo1} className="d-block w-100" alt="Company Logo 1" />
            </a>
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <a href="#">
              <img src={logo2} className="d-block w-100" alt="Company Logo 2" />
            </a>
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <a href="#">
              <img src={logo3} className="d-block w-100" alt="Company Logo 3" />
            </a>
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <a href="#">
              <img src={logo4} className="d-block w-100" alt="Company Logo 4" />
            </a>
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <a href="#">
              <img src={logo5} className="d-block w-100" alt="Company Logo 5" />
            </a>
          </div>
          <div className="carousel-item" data-bs-interval="2000">
            <a href="#">
              <img src={logo6} className="d-block w-100" alt="Company Logo 6" />
            </a>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#sponsorCarousel"
          role="button"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#sponsorCarousel"
          role="button"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </a>
      </div>
    </>
  );
}

export default IndustrialAutomation;
