import { useState, useEffect } from "react";
// import { Carousel } from 'react-bootstrap';
import "@fortawesome/fontawesome-free/css/all.min.css";
import image1 from "../../assets/images/Home/HomeLeaders_1.jpg";
import image2 from "../../assets/images/Home/HomeLeaders_2.jpg";
import image3 from "../../assets/images/Home/HomeLeaders_3.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import OwlCarousel from "react-owl-carousel";
import data from "../Apiurl/meta_api.js";
import { Helmet } from "react-helmet";
import preloader from "../../assets/images/preloader.gif";

// import review1 from "../../assets/images/Home/Review_1.jpg";
// import review2 from "../../assets/images/Home/Review_2.jpg";
// import review3 from "../../assets/images/Home/Review_3.jpg";
// import review4 from "../../assets/images/Home/Review_4.jpg";

import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { useNavigate } from "react-router-dom";

function Home() {
  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleViewCourse = () => {
    navigate("/courses");
  };

  const fetchData = () => {
    const HomeMeta = data[0]?.home_page;
    if (HomeMeta) {
      setMeta(HomeMeta);
    }
  };

  useEffect(() => {
    fetchData();
    AOS.init({
      duration: 2000,
      once: true,
    });
  }, []);

  const testimonials = [
    {
      // image: review1,
      name: "Ethan",
      profession: "Students",
      text: "I was very much satisfied learning at this training Institute. Especially with the way of teaching, they gave individual attention for each and every students and I had a very good experience which brought me some confidence for facing my placements.",
    },
    {
      // image: review2,
      name: "Daniel Martin",
      profession: "Students",
      text: "I’m a working professional already. As I’m trying to switchover my career path to testing, I thought of upgrading myself. So I started learning Manual testing and then automation testing from TIB Academy. I’m still learning and getting support from my trainer.",
    },
    {
      // image: review3,
      name: "Martin Daniel",
      profession: "Students",
      text: "I started this training just to aware of a new and trending technologies. I joined here for both selenium and QTP courses to have a better exposure in software testing. So, I did many mini-projects in my course period with the guidance of my trainer. ",
    },
    {
      // image: review4,
      name: "Doe John",
      profession: "Students",
      text: "I did software testing course as the whole package where all the concepts from basic to advanced has been covered. It includes Manual testing + Java + Selenium. Very helpful training. Now after my training, I got a very good confidence in automation testing.",
    },
  ];

  const carouselOptions = {
    items: 2,
    loop: true,
    nav: true,
    margin: 23,
    marginLeft: 28,
    autoplay: true,
    autoplayTimeout: 2000, // 2 seconds
    autoplayHoverPause: true, // Pause on hover
    responsive: {
      0: {
        items: 1, // 1 item for screens 0px to 599px
      },
      600: {
        items: 2, // 2 items for screens 600px and above
      },
      1200: {
        items: 2, // 3 items for screens 1000px and above
      },
      1201: {
        items: 3, // 3 items for screens 1000px and above
      },
    },
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [activeDiv, setActiveDiv] = useState(1);
  const [imageSrc, setImageSrc] = useState(image1);

  const fullText = `We offer a variety of industrial automation training programs, including hands-on experience with both software and hardware solutions. Our expertise spans PLC programming, SCADA systems, HMI design, and VFD integration. Whether you're looking to enhance your skills or upgrade your team's knowledge, our customized training courses are designed to meet your specific needs.
We also provide training on control panel design, system retrofitting, and upgrades with the latest automation technologies. Learn how to implement and manage robust automation systems that improve efficiency and productivity in your operations.
`;

  const previewText =
    fullText.length > 0 ? `${fullText.substring(0, 0)}...` : fullText;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClick = (divIndex, imgSrc) => {
    setActiveDiv(divIndex);
    setImageSrc(imgSrc);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  // if (loading) {
  //   return (
  //     <div className="preloader">
  //       <img src={preloader} alt="Loading..." />
  //     </div>
  //   );
  // }

  return (
    <>
      {/* <!-- Carousel Container --> */}

      <Helmet>
        <title>{meta ? meta.title : "Home"}</title>
      </Helmet>

      <div class="banner-area banner-style-one content-top-heading less-paragraph text-normal">
        <div
          id="bootcarousel"
          class="carousel slide animate_text carousel-fade"
          data-ride="carousel"
          style={{ height: "600px" }}
        >
          <div class="carousel-inner text-light carousel-zoom">
            <div class="carousel-item active">
              <div className="slider-thumb bg-fixed">
                {/* <img src={require("../../assets/images/Home/HomeCarousel_1.jpg")}
                  style={{ height: '600px', width: '100%', objectFit: "cover" }}
                  alt="Thumb" className='  '
                /> */}
                <video
                  controls={false}
                  playsInline
                  style={{
                    controls: "none",
                    height: "600px",
                    width: "100%",
                    objectFit: "cover",
                    position: "relative",
                  }}
                  alt="Thumb"
                  className="video"
                  autoPlay
                  muted
                  loop
                >
                  <source
                    src={require("../../assets/images/Home/SH Automation video.mp4")}
                    type="video/mp4"
                  />
                </video>
              </div>
              <div class="box-table shadow dark " style={{ height: "600px" }}>
                <div class="box-cell">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-7">
                        <div class="content text-start">
                          <h3 data-animation="animated slideInLeft">
                            Reach you career
                          </h3>
                          <h1 data-animation="animated slideInUp">
                            Master industrial automation with top-tier training.
                          </h1>
                          <a
                            data-animation="animated slideInUp"
                            class="btn btn-light border btn-md"
                            href="#"
                          >
                            Learn more
                          </a>{" "}
                          &nbsp;
                          <a
                            data-animation="animated slideInUp"
                            class="btn btn-theme effect btn-md"
                            href="#"
                            onClick={handleViewCourse}
                          >
                            View Courses
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div className="slider-thumb bg-fixed">
                <img
                  src={require("../../assets/images/Bannar/banner3 alter 2.jpg")}
                  style={{ height: "600px", width: "100%", objectFit: "cover" }}
                  alt="Thumb"
                  loading="lazy"
                  className="  "
                />
              </div>

              <div class="box-table shadow dark" style={{ height: "600px" }}>
                <div class="box-cell">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-7">
                        <div class="content text-start">
                          <h3 data-animation="animated slideInLeft">
                            We're glade to see you
                          </h3>
                          <h1 data-animation="animated slideInUp">
                            explore our brilliant graduates
                          </h1>
                          <a
                            data-animation="animated slideInUp"
                            class="btn btn-light border btn-md  "
                            href="#"
                          >
                            Learn more
                          </a>{" "}
                          &nbsp;
                          <a
                            data-animation="animated slideInUp"
                            class="btn btn-theme effect btn-md  "
                            href="#"
                          >
                            View Courses
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div className="slider-thumb bg-fixed">
                <img
                  src={require("../../assets/images/Bannar/banner3 alter.jpg")}
                  style={{ height: "600px", width: "100%", objectFit: "cover" }}
                  alt="Thumb"
                  loading="lazy"
                  className="  "
                />
              </div>
              <div
                class="box-table shadow dark"
                style={{ height: "600px", objectFit: "cover" }}
              >
                <div class="box-cell">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-7">
                        <div class="content text-start">
                          <h3 data-animation="animated slideInLeft">
                            The goal of education
                          </h3>
                          <h1 data-animation="animated slideInUp">
                            Join the bigest community of Scimitar Hindustan
                          </h1>
                          <a
                            data-animation="animated slideInUp"
                            class="btn btn-light border btn-md"
                            href="#"
                          >
                            Learn more
                          </a>{" "}
                          &nbsp;
                          <a
                            data-animation="animated slideInUp"
                            class="btn btn-theme effect btn-md mx-s"
                            href="#"
                          >
                            View Courses
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            class="carousel-control-prev left carousel-control"
            type="button"
            data-bs-target="#bootcarousel"
            data-bs-slide="prev"
          >
            <i class="fa fa-angle-left"></i>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next right carousel-control "
            type="button"
            data-bs-target="#bootcarousel"
            data-bs-slide="next"
          >
            <i class="fa fa-angle-right"></i>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      {/* <!-- Carousel Container end --> */}

      {/* <!-- Start About --> */}
      <div className="about-area default-padding">
        <div className="container">
          <div className="about-info">
            {/* <div className="row">
              <div className="col-lg-6 col-md-12 thumb">
                <img
                  className="digital_home"
                  data-aos="zoom-in"
                  src={require("../../assets/images/Home/digtalmarketing.jpg")}
                  loading="lazy"
                  alt="Thumb"
                />
              </div>
              <div className="col-lg-6 col-md-12 info">
                <h5 className="text-warning">Introduction</h5>
                <h2 className="commomtextcolor">
                  Digital Marketing Courses and Industrial Automation Training
                  Services
                </h2>
                <p className="commonPages" data-aos="fade-right">
                  We are your trusted partner in digital transformation,
                  offering a comprehensive range of Digital Marketing courses
                  and Industrial automation training services tailored to meet
                  the needs of modern businesses. Our team of experts combines
                  creativity, technology, and strategic thinking to drive your
                  business forward in the digital age.
                </p>
                <p className="commonPages">
                  {isExpanded ? fullText : previewText}
                </p>
                <button
                  className="btn btn-success border btn-md"
                  onClick={handleToggle}
                >
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
              </div>
            </div> */}

            <div className="row">
              <div className="col-lg-6 col-md-12 thumb">
                <img
                  className="digital_home"
                  data-aos="zoom-in"
                  src={require("../../assets/images/Home/home 1b.jpg")}
                  loading="lazy"
                  alt="Thumb"
                />
              </div>
              <div className="col-lg-6 col-md-12 info" data-aos="fade-right">
                <h1 className="text-warning ">Introduction</h1>
                <h3 className="commomtextcolor ">
                  Industrial Automation Training
                </h3>
                <p className="commonPages">
                  At Scimitar Hindustan, we are your trusted partner in
                  industrial automation, offering a comprehensive range of
                  Industrial Automation Training services tailored to meet the
                  demands of modern industries. Our expert team combines
                  cutting-edge technology and industry knowledge to help you
                  stay ahead in the ever-evolving field of automation.
                </p>
                <p className="commonPages">
                  {isExpanded ? fullText : previewText}
                </p>
                <button
                  className="btn btn-success border btn-md"
                  onClick={handleToggle}
                >
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>

            <div className="our-features">
              <div className="row">
                <div className="col-lg-4 our_feature_column col-md-4">
                  <div className="item mariner">
                    <div className="icon">
                      <i
                        className="fas fa-shield-alt"
                        style={{ color: "#FFB606" }}
                      ></i>
                    </div>
                    <div className="info">
                      <h4>Expert Faculty</h4>
                      <a href="/">Read More</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 our_feature_column col-md-4">
                  <div className="item java">
                    <div className="icon">
                      <i
                        className="fas fa-book"
                        style={{ color: "#FFB606" }}
                      ></i>
                    </div>
                    <div className="info">
                      <h4>Industry Certifications</h4>
                      <a href="/">Read More</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 our_feature_column col-md-4">
                  <div className="item malachite ">
                    <div className="icon">
                      <i
                        className="fas fa-graduation-cap"
                        style={{ color: "#FFB606" }}
                      ></i>
                    </div>
                    <div className="info">
                      <h4>Placement Assistance</h4>
                      <a href="/">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="OurService_">
        <h1 className="text-warning text-center mb-5 Our_Service">
          Our Services
        </h1>
      </div>

      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-6 col-md-6" data-aos="fade-up">
            <img
              src={require("../../assets/images/Home/home2 3.jpg")}
              loading="lazy"
              className="Home_Service"
              alt=""
            />
          </div>
          <div className="col-lg-6 col-md-6" data-aos="fade-down">
            <h3 className="mt-3 commomtextcolor ">Digital Marketing Courses</h3>
            <p className="commonPages mt-4">
              Unlock the full potential of your digital presence with our
              expert-led training program. Whether you're just starting out or
              looking to refine your skills, our comprehensive curriculum covers
              everything from SEO and PPC to social media and content marketing.
              Gain hands-on experience, learn cutting-edge strategies, and earn
              a recognized certification that sets you apart in the industry.
              Equip yourself with the tools and knowledge needed to drive
              success in today’s fast-paced digital world.
            </p>
            <div className="mt-4 " data-aos="fade-left">
              <h6 className="">
                <i
                  className="fas fa-arrow-right text-warning mx-2 "
                  style={{ fontSize: "20px" }}
                ></i>{" "}
                Expert-Led Training
              </h6>
              <h6 className="">
                <i
                  className="fas fa-arrow-right text-warning mt-2 mx-2"
                  style={{ fontSize: "20px" }}
                ></i>{" "}
                Comprehensive Curriculum
              </h6>
              <h6 className="">
                <i
                  className="fas fa-arrow-right text-warning mt-2 mx-2"
                  style={{ fontSize: "20px" }}
                ></i>{" "}
                Hands-On Learning
              </h6>
              <h6 className="">
                <i
                  className="fas fa-arrow-right text-warning mt-2 mx-2"
                  style={{ fontSize: "20px" }}
                ></i>{" "}
                Certification
              </h6>
              <h6 className="">
                <i
                  className="fas fa-arrow-right text-warning mt-2 mx-2"
                  style={{ fontSize: "20px" }}
                ></i>{" "}
                Flexible Learning Options
              </h6>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Start Why Choose Us --> */}
      <div className="">
        <h1 className="text-warning text-center">Leaders</h1>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-6  text-start">
            <h3 className="commomtextcolor">
              Leaders in Industrial Automation Training
            </h3>
            <p className="mt-4 commonPages">
              Discover the future of efficiency and innovation with our
              specialized industrial automation training programs. Our expertly
              designed courses are tailored to meet the needs of both novices
              and seasoned professionals, helping you stay ahead in a rapidly
              evolving industry.
            </p>

            <div
              className={`training-card ${
                activeDiv === 1 ? "active" : ""
              } mb-1 text-start `}
              onClick={() => handleClick(1, image1)}
            >
              <div className="d-flex commonPages">
                <h6>
                  {" "}
                  <i className="fa-solid fa-user mx-2 mt-2  "></i>{" "}
                </h6>{" "}
                <p className="Comprehensive ">
                  Comprehensive on the Training: From fundamentals to advanced
                  systems establish a solid foundation
                </p>
              </div>
            </div>

            <div
              className={`training-card ${
                activeDiv === 2 ? "active" : ""
              } mb-1 text-start `}
              onClick={() => handleClick(2, image2)}
            >
              <div className="d-flex">
                <h6>
                  {" "}
                  <i className="fa-solid fa-bookmark mx-2  mt-2  "></i>{" "}
                </h6>
                <p className="Comprehensive ">
                  {" "}
                  Expert Instructors: Learn from industry veterans with hands-on
                  experience in the exper.
                </p>
              </div>
            </div>

            <div
              className={`training-card ${
                activeDiv === 3 ? "active" : ""
              } mb-1 text-start `}
              onClick={() => handleClick(3, image3)}
            >
              <div className="d-flex">
                <h6>
                  {" "}
                  <i className="fa-solid fa-tv mx-2  mt-2 "></i>{" "}
                </h6>{" "}
                <p className="Comprehensive">
                  Advanced in the Techniques: Master cutting-edge in the
                  technologies and the methodologies.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 Leaders_Images">
            <img
              src={imageSrc}
              alt="Training"
              style={{ borderRadius: "20px", width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>

      {/* COURSE FEATURES */}

      <div className="container-fluid ourCourses">
        <div className="mt-5">
          <h1
            className="text-warning"
            style={{ textAlign: "center", marginTop: "80px" }}
          >
            Course Features
          </h1>
        </div>
        {/* <div className="">
          <br />
          <h3 className="text-light " style={{ textAlign: "center" }}>
            Why Choose Our Courses?
          </h3>
        </div> */}
        <div className="container ">
          <div className="row">
            <div className="col-lg-4 why_choose_card">
              <div
                className="card lowerCardSession mt-5 WhyChoosecard"
                data-aos="fade-right"
              >
                <i class="fa-solid fa-person-chalkboard h1 mt-3"></i>
                <h4 className="ml-3 mt-3 text-warning">Hands-On Learning</h4>
                <p className=" commonPages pb-3 px-3">
                  Gain practical on experience through simulations and
                  real-world projects.
                </p>
              </div>
            </div>
            <div className="col-lg-4 why_choose_card">
              <div
                className="card lowerCardSession mt-5 WhyChoosecard"
                data-aos="fade-down"
              >
                <i class="fa-solid fa-person-chalkboard h1 mt-3"></i>
                <h4 className="ml-3 mt-3 text-warning">Expert Instructors</h4>
                <p className=" commonPages pb-3 px-3">
                  {" "}
                  Learn from seasoned professionals with extensive industry
                  experience.
                </p>
              </div>
            </div>
            <div className="col-lg-4 why_choose_card">
              <div
                className="card lowerCardSession mt-5 WhyChoosecard"
                data-aos="fade-left"
              >
                <i class="fa-solid fa-list h1 mt-3"></i>
                <h4 className="ml-3 mt-3 text-warning">Flexible Options</h4>
                <p className="commonPages pb-3 px-3">
                  Choose from various learning formats to fit your schedule and
                  learning style.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2 "></div>
            <div className="col-lg-4 why_choose_card mb-5">
              <div
                className="card lowerCardSession mt-5 WhyChoosecard"
                data-aos="fade-up"
              >
                <i>
                  <img
                    src={require("../../assets/images/Home/certificate.png")}
                    style={{ width: "50px", height: "50px" }}
                    loading="lazy"
                    className="mt-3"
                  />
                </i>
                <h4 className="ml-3 mt-3 text-warning">Certification</h4>
                <p className=" commonPages pb-3 px-3">
                  Earn and industry-recognized in the certificates upon
                  successful completion.
                </p>
              </div>
            </div>
            <div className="col-lg-4 why_choose_card">
              <div
                className="card lowerCardSession  WhyChooseCard5"
                data-aos="fade-up"
              >
                <i class="fa-solid fa-headset h1 mt-3"></i>
                <h4 className="ml-3 mt-3 text-warning">Career Support</h4>
                <p className="commonPages pb-3 px-3">
                  Access job placement assistance and career counseling to help
                  you advance.
                </p>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>

      {/* reviews section start  */}

      <div className="container-fluid  py-5 ">
        <div className="container">
          <div
            className="text-center mx-auto pb-5"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="text-warning">Our Students Reviews!</h1>
            {/* <h3 className="commomtextcolor">Our Students Reviews!</h3> */}
          </div>
          <OwlCarousel className="owl-theme" {...carouselOptions}>
            {testimonials.map((item, index) => (
              <div key={index} className="item">
                <div className="testimonial-item border p-4">
                  <div className="d-flex align-items-center">
                    <div className="Reviews_Images">
                      {/* <img src={item.image} alt={item.name} className="img-fluid" /> */}
                      <div className="card text-center h1 p-3">
                        <i class="fa-solid fa-user-tie h1 text-secondary"></i>
                      </div>
                    </div>
                    <div className="ms-4">
                      <h4 className="text-secondary commonPages">
                        {item.name}
                      </h4>
                      <p className="m-0 pb-3 ">{item.profession}</p>
                      <div className="d-flex pe-5 mt-3">
                        <i className="fas fa-star me-1 text-warning"></i>
                        <i className="fas fa-star me-1 text-warning"></i>
                        <i className="fas fa-star me-1 text-warning"></i>
                        <i className="fas fa-star me-1 text-warning"></i>
                        <i className="fas fa-star me-1 text-warning"></i>
                      </div>
                    </div>
                  </div>
                  <div className="border-top mt-4 pt-3">
                    <p className="mb-0 commonPages">{item.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>

      {/* review section ends */}

      {/* <div className='container'>
      <video controls={false} playsInline style={{ controls: 'none', height: "100%", width: "100%", objectFit: "cover", position: "relative" }} className="video" autoPlay muted loop>
      <source src={require("../../assets/images/Home/SH Automation video.mp4")} type="video/mp4" /></video>
      </div> */}
    </>
  );
}
export default Home;
