import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../../assets/css/stylingStyle.css'
import { Carousel } from 'react-bootstrap';

const Header = () => {

  function handelesubmit (){
    console.log("komkokoko")
  }
  return (
    <>
      {/* <!-- Preloader Start --> */}
      <div className="se-pre-con"></div>
      {/* <!-- Preloader Ends --> */}
      {/* 
            <!-- Start Header Top 
    ============================================= --> */}
      

    {/*  <!-- Start Banner 
    ============================================= --> */}

    

  

      {/* <!-- Start Why Chose Us 
    ============================================= --> */}
      {/*     <!-- Start Featured Courses 
    ============================================= --> */}
      <div id="featured-courses" className="featured-courses-area left-details default-padding">
        <div className="container">
          <div className="row">
            <div className="featured-courses-carousel owl-carousel owl-theme">
              {/* <!-- Start Single Item --> */}
              <div className="item">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="thumb">
                      {/* <img src="images/f1.jpg" alt="Thumb"> */}
                      <div className="live-view">
                        <a href="assets/img/courses/f1.jpg" className="item popup-link">
                          <i className="fa fa-camera"></i>
                        </a>
                        <a className="popup-youtube" href="https://www.youtube.com/watch?v=vQqZIFCab9o">
                          <i className="fa fa-video"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 info">
                    <h2>
                      <a href="#">Codecademy software programming</a>
                    </h2>
                    <h4>featured courses</h4>
                    <p>
                      Both rest of know draw fond post as. It agreement defective to excellent. Feebly do engage of narrow. Extensive repulsive belonging depending if promotion be zealously as. Preference inquietude ask
                    </p>
                    <h3>Learning outcomes</h3>
                    <ul>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Over 37 lectures and 55.5 hours of content!</span>
                      </li>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Testing Training Included.</span>
                      </li>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Course content designed by considering current software testing</span>
                      </li>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Practical assignments at the end of every session.</span>
                      </li>
                    </ul>
                    <a href="#" className="btn btn-theme effect btn-md" data-animation="animated slideInUp">Enroll Now</a>
                    <div className="bottom-info align-left">
                      <div className="item">
                        <h4>Author</h4>
                        <a href="#">
                          <span>Devid Honey</span>
                        </a>
                      </div>
                      <div className="item">
                        <h4>Students enrolled</h4>
                        <span>5455</span>
                      </div>
                      <div className="item">
                        <h4>Rating</h4>
                        <span className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item -->
              <!-- Start Single Item --> */}
              <div className="item">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="thumb">
                      {/* <img src="images/f2.jpg" alt="Thumb"> */}
                      <div className="live-view">
                        <a href="assets/img/courses/f2.jpg" className="item popup-link">
                          <i className="fa fa-camera"></i>
                        </a>
                        <a className="popup-youtube" href="https://www.youtube.com/watch?v=vQqZIFCab9o">
                          <i className="fa fa-video"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 info">
                    <h2>
                      <a href="#">Data analysis and data science</a>
                    </h2>
                    <h4>featured courses</h4>
                    <p>
                      Both rest of know draw fond post as. It agreement defective to excellent. Feebly do engage of narrow. Extensive repulsive belonging depending if promotion be zealously as. Preference inquietude ask
                    </p>
                    <h3>Learning outcomes</h3>
                    <ul>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Over 37 lectures and 55.5 hours of content!</span>
                      </li>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Testing Training Included.</span>
                      </li>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Course content designed by considering current software testing</span>
                      </li>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Practical assignments at the end of every session.</span>
                      </li>
                    </ul>
                    <a href="#" className="btn btn-theme effect btn-md" data-animation="animated slideInUp">Enroll Now</a>
                    <div className="bottom-info align-left">
                      <div className="item">
                        <h4>Author</h4>
                        <a href="#">
                          <span>Devid Honey</span>
                        </a>
                      </div>
                      <div className="item">
                        <h4>Students enrolled</h4>
                        <span>5455</span>
                      </div>
                      <div className="item">
                        <h4>Rating</h4>
                        <span className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item -->
              <!-- Start Single Item --> */}
              <div className="item">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="thumb">
                      {/* <img src="images/f3.jpg" alt="Thumb"> */}
                      <div className="live-view">
                        <a href="assets/img/courses/f3.jpg" className="item popup-link">
                          <i className="fa fa-camera"></i>
                        </a>
                        <a className="popup-youtube" href="https://www.youtube.com/watch?v=vQqZIFCab9o">
                          <i className="fa fa-video"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 info">
                    <h2>
                      <a href="#">Graphic and interactive design</a>
                    </h2>
                    <h4>featured courses</h4>
                    <p>
                      Both rest of know draw fond post as. It agreement defective to excellent. Feebly do engage of narrow. Extensive repulsive belonging depending if promotion be zealously as. Preference inquietude ask
                    </p>
                    <h3>Learning outcomes</h3>
                    <ul>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Over 37 lectures and 55.5 hours of content!</span>
                      </li>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Testing Training Included.</span>
                      </li>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Course content designed by considering current software testing</span>
                      </li>
                      <li>
                        <i className="fas fa-check-double"></i>
                        <span>Practical assignments at the end of every session.</span>
                      </li>
                    </ul>
                    <a href="#" className="btn btn-theme effect btn-md" data-animation="animated slideInUp">Enroll Now</a>
                    <div className="bottom-info align-left">
                      <div className="item">
                        <h4>Author</h4>
                        <a href="#">
                          <span>Devid Honey</span>
                        </a>
                      </div>
                      <div className="item">
                        <h4>Students enrolled</h4>
                        <span>5455</span>
                      </div>
                      <div className="item">
                        <h4>Rating</h4>
                        <span className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Single Item --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Featured Courses --> */}

      {/* <!-- Start Popular Courses 
    ============================================= --> */}
      <div className="popular-courses circle bg-gray carousel-shadow default-padding">
        <div className="container">
          <div className="row">
            <div className="site-heading text-center">
              <div className="col-lg-8 offset-lg-2">
                <h2>Popular Courses</h2>
                <p>
                  Discourse assurance estimable applauded to so. Him everything melancholy uncommonly but solicitude inhabiting projection off. Connection stimulated estimating excellence an to impression.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="popular-courses-items popular-courses-carousel owl-carousel owl-theme">
                {/* <!-- Single Item --> */}
                <div className="item">
                  <div className="thumb">
                    <a href="#">
                      {/* <img src="images/1_2.jpg" alt="Thumb"> */}
                    </a>
                    <div className="price">Price: Free</div>
                  </div>
                  <div className="info">
                    <div className="author-info">
                      <div className="thumb">
                        {/* <a href="#"><img src="images/7_1.jpg" alt="Thumb"></a> */}
                      </div>
                      <div className="others">
                        <a href="#">Munil Druva</a>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                          <span>4.5 (23,890)</span>
                        </div>
                      </div>
                    </div>
                    <h4><a href="#">data science and software</a></h4>
                    <p>
                      Would day nor ask walls known. But preserved advantage are but and certainty earnestly enjoyment.
                    </p>
                    <div className="bottom-info">
                      <ul>
                        <li>
                          <i className="fas fa-user"></i> 6,690
                        </li>
                        <li>
                          <i className="fas fa-clock"></i> 16:00
                        </li>
                      </ul>
                      <a href="#">Enroll Now</a>
                    </div>
                  </div>
                </div>
                {/* <!-- End Single Item -->
                <!-- Single Item --> */}
                <div className="item">
                  <div className="thumb">
                    <a href="#">
                      {/* <img src="images/2_2.jpg" alt="Thumb"> */}
                    </a>
                    <div className="price">Price: $12</div>
                  </div>
                  <div className="info">
                    <div className="author-info">
                      <div className="thumb">
                        {/* <a href="#"><img src="images/8_1.jpg" alt="Thumb"></a> */}
                      </div>
                      <div className="others">
                        <a href="#">Akua Paul</a>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                          <span>5 (867)</span>
                        </div>
                      </div>
                    </div>
                    <h4><a href="#">Stanford Engineering</a></h4>
                    <p>
                      Would day nor ask walls known. But preserved advantage are but and certainty earnestly enjoyment.
                    </p>
                    <div className="bottom-info">
                      <ul>
                        <li>
                          <i className="fas fa-user"></i> 6,690
                        </li>
                        <li>
                          <i className="fas fa-clock"></i> 16:00
                        </li>
                      </ul>
                      <a href="#">Enroll Now</a>
                    </div>
                  </div>
                </div>
                {/* <!-- End Single Item -->
                <!-- Single Item --> */}
                <div className="item">
                  <div className="thumb">
                    <a href="#">
                      {/* <img src="images/3_2.jpg" alt="Thumb"> */}
                    </a>
                    <div className="price">Price: Free</div>
                  </div>
                  <div className="info">
                    <div className="author-info">
                      <div className="thumb">
                        {/* <a href="#"><img src="images/9.jpg" alt="Thumb"></a> */}
                      </div>
                      <div className="others">
                        <a href="#">Jonathom Kiyam</a>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <span>4.9 (2,655)</span>
                        </div>
                      </div>
                    </div>
                    <h4><a href="#">Covers Big Data analysis</a></h4>
                    <p>
                      Would day nor ask walls known. But preserved advantage are but and certainty earnestly enjoyment.
                    </p>
                    <div className="bottom-info">
                      <ul>
                        <li>
                          <i className="fas fa-user"></i> 6,690
                        </li>
                        <li>
                          <i className="fas fa-clock"></i> 16:00
                        </li>
                      </ul>
                      <a href="#">Enroll Now</a>
                    </div>
                  </div>
                </div>
                {/* <!-- End Single Item -->
                <!-- Single Item --> */}
                <div className="item">
                  <div className="thumb">
                    <a href="#">
                      {/* <img src="images/4_2.jpg" alt="Thumb"> */}
                    </a>
                    <div className="price">Price: $46</div>
                  </div>
                  <div className="info">
                    <div className="author-info">
                      <div className="thumb">
                        {/* <a href="#"><img src="images/2_3.jpg" alt="Thumb"></a> */}
                      </div>
                      <div className="others">
                        <a href="#">Huma Park</a>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                          <span>4.4 (16,465)</span>
                        </div>
                      </div>
                    </div>
                    <h4><a href="#">professional web development</a></h4>
                    <p>
                      Would day nor ask walls known. But preserved advantage are but and certainty earnestly enjoyment.
                    </p>
                    <div className="bottom-info">
                      <ul>
                        <li>
                          <i className="fas fa-user"></i> 6,690
                        </li>
                        <li>
                          <i className="fas fa-clock"></i> 16:00
                        </li>
                      </ul>
                      <a href="#">Enroll Now</a>
                    </div>
                  </div>
                </div>
                {/* <!-- End Single Item -->
                <!-- Single Item --> */}
                <div className="item">
                  <div className="thumb">
                    <a href="#">
                      {/* <img src="images/5_1.jpg" alt="Thumb"> */}
                    </a>
                    <div className="price">Price: $124</div>
                  </div>
                  <div className="info">
                    <div className="author-info">
                      <div className="thumb">
                        {/* <a href="#"><img src="images/3_3.jpg" alt="Thumb"></a> */}
                      </div>
                      <div className="others">
                        <a href="#">Prokash Timer</a>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star-half-alt"></i>
                          <span>5 (7,890)</span>
                        </div>
                      </div>
                    </div>
                    <h4><a href="#">Java Programming Masterclass</a></h4>
                    <p>
                      Would day nor ask walls known. But preserved advantage are but and certainty earnestly enjoyment.
                    </p>
                    <div className="bottom-info">
                      <ul>
                        <li>
                          <i className="fas fa-user"></i> 6,690
                        </li>
                        <li>
                          <i className="fas fa-clock"></i> 16:00
                        </li>
                      </ul>
                      <a href="#">Enroll Now</a>
                    </div>
                  </div>
                </div>
                {/* <!-- End Single Item --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Popular Courses -->

      <!-- Start Top Categories 
    ============================================= --> */}
      <div id="top-categories" className="top-cat-area default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="site-heading text-center">
              <div className="col-lg-8 offset-lg-2">
                <h2>Top Categories</h2>
                <p>
                  Discourse assurance estimable applauded to so. Him everything melancholy uncommonly but solicitude inhabiting projection off. Connection stimulated estimating excellence an to impression.
                </p>
              </div>
            </div>
          </div>
          <div className="top-cat-items">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6 equal-height">
                <div className="item" >
                  {/* style="background-image: url(images/1.jpg);" */}
                  <a href="#">
                    <i className="flaticon-feature"></i>
                    <div className="info">
                      <h4>software engineering</h4>
                      <span>(1,226) Topics</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 equal-height">
                <div className="item" >
                  {/* style="background-image: url(images/2.jpg);" */}
                  <a href="#">
                    <i className="flaticon-interaction"></i>
                    <div className="info">
                      <h4>Interactive Programming</h4>
                      <span>(2,366) Topics</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 equal-height">
                <div className="item" >
                  {/* style="background-image: url(images/3.jpg);" */}
                  <a href="#">
                    <i className="flaticon-conveyor"></i>
                    <div className="info">
                      <h4>Quantum Mechanics</h4>
                      <span>(766) Topics</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 equal-height">
                <div className="item" >
                  {/* style="background-image: url(images/4.jpg);" */}
                  <a href="#">
                    <i className="flaticon-education"></i>
                    <div className="info">
                      <h4>Preventing Dementia</h4>
                      <span>(4,500) Topics</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 equal-height">
                <div className="item" >
                  {/* style="background-image: url(images/5.jpg);" */}
                  <a href="#">
                    <i className="flaticon-potential"></i>
                    <div className="info">
                      <h4>Hidden Potential</h4>
                      <span>(975) Topics</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 equal-height">
                <div className="item" >
                  {/* style="background-image: url(images/6.jpg);" */}
                  <a href="#">
                    <i className="flaticon-print"></i>
                    <div className="info">
                      <h4>Introduction Programming</h4>
                      <span>(3,340) Topics</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 equal-height">
                <div className="item" >
                  <a href="#">
                    {/* style="background-image: url(images/7.jpg);" */}
                    <i className="flaticon-translate"></i>
                    <div className="info">
                      <h4>Machine Learning</h4>
                      <span>(7,800) Topics</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 equal-height">
                <div className="item" >
                  {/* style="background-image: url(images/8.jpg);" */}
                  <a href="#">
                    <i className="flaticon-firewall"></i>
                    <div className="info">
                      <h4>Maintaining a Mindful</h4>
                      <span>(24,80) Topics</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Top Categories -->

      <!-- Start Advisor Area
    ============================================= --> */}
      <section id="advisor" className="advisor-area bg-gray carousel-shadow default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="site-heading text-center">
              <div className="col-lg-8 offset-lg-2">
                <h2>Experience Advisors</h2>
                <p>
                  Able an hope of body. Any nay shyness article matters own removal nothing his forming. Gay own additions education satisfied the perpetual. If he cause manor happy. Without farther she exposed saw man led. Along on happy could cease green oh.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="advisor-items advisor-carousel-solid owl-carousel owl-theme text-center text-light">
                {/* <!-- Single Item --> */}
                <div className="advisor-item">
                  <div className="info-box">
                    {/* <img src="images/1_3.jpg" alt="Thumb"> */}
                    <div className="info-title">
                      <h4>Professon. Nuri Paul</h4>
                      <span>Chemistry specialist</span>
                      <div className="social">
                        <ul>
                          <li className="facebook">
                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                          </li>
                          <li className="twitter">
                            <a href="#"><i className="fab fa-twitter"></i></a>
                          </li>
                          <li className="pinterest">
                            <a href="#"><i className="fab fa-pinterest"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Single Item -->
                <!-- Single Item --> */}
                <div className="advisor-item">
                  <div className="info-box">
                    {/* <img src="images/2_4.jpg" alt="Thumb"> */}
                    <div className="info-title">
                      <h4>Monayem Pruda</h4>
                      <span>Senior Developer</span>
                      <div className="social">
                        <ul>
                          <li className="facebook">
                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                          </li>
                          <li className="twitter">
                            <a href="#"><i className="fab fa-twitter"></i></a>
                          </li>
                          <li className="pinterest">
                            <a href="#"><i className="fab fa-pinterest"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Single Item -->
                <!-- Single Item --> */}
                <div className="advisor-item">
                  <div className="info-box">
                    {/* <img src="images/3_4.jpg" alt="Thumb"> */}
                    <div className="info-title">
                      <h4>Dr. Bubly Minu</h4>
                      <span>Science specialist</span>
                      <div className="social">
                        <ul>
                          <li className="facebook">
                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                          </li>
                          <li className="twitter">
                            <a href="#"><i className="fab fa-twitter"></i></a>
                          </li>
                          <li className="pinterest">
                            <a href="#"><i className="fab fa-pinterest"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Single Item -->
                <!-- Single Item --> */}
                <div className="advisor-item">
                  <div className="info-box">
                    {/* <img src="images/4_3.jpg" alt="Thumb"> */}
                    <div className="info-title">
                      <h4>Professon. John Doe</h4>
                      <span>Senior Writter</span>
                      <div className="social">
                        <ul>
                          <li className="facebook">
                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                          </li>
                          <li className="twitter">
                            <a href="#"><i className="fab fa-twitter"></i></a>
                          </li>
                          <li className="pinterest">
                            <a href="#"><i className="fab fa-pinterest"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Single Item -->
                <!-- Single Item --> */}
                <div className="advisor-item">
                  <div className="info-box">
                    {/* <img src="images/5_2.jpg" alt="Thumb"> */}
                    <div className="info-title">
                      <h4>Professon. John Doe</h4>
                      <span>Senior Writter</span>
                      <div className="social">
                        <ul>
                          <li className="facebook">
                            <a href="#"><i className="fab fa-facebook-f"></i></a>
                          </li>
                          <li className="twitter">
                            <a href="#"><i className="fab fa-twitter"></i></a>
                          </li>
                          <li className="pinterest">
                            <a href="#"><i className="fab fa-pinterest"></i></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Single Item --> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--End Advisor Area -->

      <!-- Start Fun Factor 
    ============================================= --> */}
      <div className="fun-factor-area default-padding bottom-less text-center bg-fixed shadow dark-hard" >
        {/* style="background-image: url(images/2_1.jpg);" */}
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="icon">
                  <i className="flaticon-contract"></i>
                </div>
                <div className="info">
                  <span className="timer" data-to="212" data-speed="5000"></span>
                  <span className="medium">National Awards</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="icon">
                  <i className="flaticon-professor"></i>
                </div>
                <div className="info">
                  <span className="timer" data-to="128" data-speed="5000"></span>
                  <span className="medium">Best Teachers</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="icon">
                  <i className="flaticon-online"></i>
                </div>
                <div className="info">
                  <span className="timer" data-to="8970" data-speed="5000"></span>
                  <span className="medium">Students Enrolled</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="icon">
                  <i className="flaticon-reading"></i>
                </div>
                <div className="info">
                  <span className="timer" data-to="640" data-speed="5000"></span>
                  <span className="medium">Cources</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Fun Factor -->

      <!-- Start Event
    ============================================= --> */}
      <section id="event" className="event-area default-padding">
        <div className="container">
          <div className="row">
            <div className="site-heading text-center">
              <div className="col-lg-8 offset-lg-2">
                <h2>Upcoming Events</h2>
                <p>
                  Able an hope of body. Any nay shyness article matters own removal nothing his forming. Gay own additions education satisfied the perpetual. If he cause manor happy. Without farther she exposed saw man led. Along on happy could cease green oh.
                </p>
              </div>
            </div>
          </div>
          <div className="event-items">
            {/* <!-- Single Item --> */}
            <div className="item horizontal">
              <div className="col-lg-6 thumb bg-cover" >
                {/* style="background-image: url(images/1_1.jpg);" */}
                <div className="date">
                  <h4><span>12</span> Dec, 2018</h4>
                </div>
              </div>
              <div className="col-lg-6 info">
                <h4>
                  <a href="#">Secondary Schools United Nations</a>
                </h4>
                <div className="meta">
                  <ul>
                    <li><i className="fas fa-calendar-alt"></i> 15 Oct, 2019</li>
                    <li><i className="fas fa-clock"></i>  8:00 AM - 5:00 PM</li>
                    <li><i className="fas fa-map"></i> California, TX 70240 </li>
                  </ul>
                </div>
                <p>
                  Early had add equal china quiet visit. Appear an manner as no limits either praise in. In in written on charmed justice is amiable farther besides. Law insensible middletons unsatiable for apartments boy delightful unreserved.
                </p>
                <a href="#" className="btn btn-dark effect btn-sm">
                  <i className="fas fa-chart-bar"></i> Book Now
                </a>
                <a href="#" className="btn btn-gray btn-sm">
                  <i className="fas fa-ticket-alt"></i> 43 Available
                </a>
              </div>
            </div>
            {/* <!-- Single Item --> */}

            <div className="row">
              <div className="col-lg-6">
                {/* <!-- Single Item --> */}
                <div className="item vertical">
                  <div className="thumb">
                    {/* <img src="images/2_5.jpg" alt="Thumb"> */}
                    <div className="date">
                      <h4><span>27</span> Feb, 2019</h4>
                    </div>
                  </div>
                  <div className="info">
                    <h4>
                      <a href="#">Social Science &amp; Humanities</a>
                    </h4>
                    <div className="meta">
                      <ul>
                        <li><i className="fas fa-calendar-alt"></i> 15 Oct, 2019</li>
                        <li><i className="fas fa-clock"></i>  8:00 AM - 5:00 PM</li>
                        <li><i className="fas fa-map"></i> California, TX 70240 </li>
                      </ul>
                    </div>
                    <p>
                      Early had add equal china quiet visit. Appear an manner as no limits either praise in. In in written on charmed justice is amiable farther besides. Law insensible middletons unsatiable for apartments boy delightful unreserved.
                    </p>
                    <a href="#" className="btn btn-dark effect btn-sm">
                      <i className="fas fa-chart-bar"></i> Book Now
                    </a>
                    <a href="#" className="btn btn-gray btn-sm">
                      <i className="fas fa-ticket-alt"></i> 189 Available
                    </a>
                  </div>
                </div>
                {/* <!-- Single Item --> */}
              </div>
              <div className="col-lg-6">
                {/* <!-- Single Item --> */}
                <div className="item vertical">
                  <div className="thumb">
                    {/* <img src="images/3_5.jpg" alt="Thumb"> */}
                    <div className="date">
                      <h4><span>15</span> Mar, 2019</h4>
                    </div>
                  </div>
                  <div className="info">
                    <h4>
                      <a href="#">Actualized Leadership Network Seminar</a>
                    </h4>
                    <div className="meta">
                      <ul>
                        <li><i className="fas fa-calendar-alt"></i> 15 Oct, 2019</li>
                        <li><i className="fas fa-clock"></i>  8:00 AM - 5:00 PM</li>
                        <li><i className="fas fa-map"></i> California, TX 70240 </li>
                      </ul>
                    </div>
                    <p>
                      Early had add equal china quiet visit. Appear an manner as no limits either praise in. In in written on charmed justice is amiable farther besides. Law insensible middletons unsatiable for apartments boy delightful unreserved.
                    </p>
                    <a href="#" className="btn btn-dark effect btn-sm">
                      <i className="fas fa-chart-bar"></i> Book Now
                    </a>
                    <a href="#" className="btn btn-gray btn-sm">
                      <i className="fas fa-ticket-alt"></i> 32 Available
                    </a>
                  </div>
                </div>
                {/* <!-- Single Item --> */}
              </div>
            </div>

            {/* <!-- Single Item --> */}
            <div className="item horizontal">
              <div className="col-lg-6 thumb bg-cover" >
                {/* style="background-image: url(images/4_1.jpg);" */}
                <div className="date">
                  <h4><span>24</span> Apr, 2019</h4>
                </div>
              </div>
              <div className="col-lg-6 info">
                <h4>
                  <a href="#">International Conference on Art Business</a>
                </h4>
                <div className="meta">
                  <ul>
                    <li><i className="fas fa-calendar-alt"></i> 15 Oct, 2019</li>
                    <li><i className="fas fa-clock"></i>  8:00 AM - 5:00 PM</li>
                    <li><i className="fas fa-map"></i> California, TX 70240 </li>
                  </ul>
                </div>
                <p>
                  Early had add equal china quiet visit. Appear an manner as no limits either praise in. In in written on charmed justice is amiable farther besides. Law insensible middletons unsatiable for apartments boy delightful unreserved.
                </p>
                <a href="#" className="btn btn-dark effect btn-sm">
                  <i className="fas fa-chart-bar"></i> Book Now
                </a>
                <a href="#" className="btn btn-gray btn-sm">
                  <i className="fas fa-ticket-alt"></i> 12 Available
                </a>
              </div>
            </div>
            {/* <!-- Single Item --> */}

            <div className="more-btn col-md-12 text-center">
              <a href="#" className="btn btn-dark border btn-md">View All Events</a>
            </div>

          </div>
        </div>
      </section>
      {/* <!-- End Event -->

      <!-- Start Registration 
    ============================================= --> */}
      <div className="reg-area default-padding-top bg-gray">
        <div className="container">
          <div className="row">
            <div className="reg-items">
              <div className="col-lg-6 reg-form default-padding-bottom">
                <div className="site-heading text-left">
                  <h2>Get a Free online Registration</h2>
                  <p>
                    written on charmed justice is amiable farther besides. Law insensible middletons unsatiable for apartments boy delightful unreserved.
                  </p>
                </div>
                <form action="#">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        {/* <input className="form-control" placeholder="First Name" type="text"> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        {/* <input className="form-control" placeholder="Last Name" type="text"> */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        {/* <input className="form-control" placeholder="Email*" type="email"> */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <select>
                          <option value="1">Chose Subject</option>
                          <option value="2">Computer Engineering</option>
                          <option value="4">Accounting Technologies</option>
                          <option value="5">Web Development</option>
                          <option value="6">Machine Language</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        {/* <input className="form-control" placeholder="Phone" type="text"> */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button type="submit">
                        Rigister Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6 thumb">
                {/* <img src="images/contact.png" alt="Thumb"> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Registration -->

      <!-- Start Testimonials 
    ============================================= --> */}
      <div className="testimonials-area carousel-shadow default-padding bg-dark text-light">
        <div className="container">
          <div className="row">
            <div className="site-heading text-center">
              <div className="col-lg-8 offset-lg-2">
                <h2>Students Review</h2>
                <p>
                  Able an hope of body. Any nay shyness article matters own removal nothing his forming. Gay own additions education satisfied the perpetual. If he cause manor happy. Without farther she exposed saw man led. Along on happy could cease green oh.
                </p>
              </div>
            </div>
          </div>
          <div className="clients-review-carousel owl-carousel owl-theme">
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="row align-items-center">
                <div className="col-lg-5 thumb">
                  {/* <img src="images/2_3.jpg" alt="Thumb"> */}
                </div>
                <div className="col-lg-7 info">
                  <p>
                    Procuring continued suspicion its ten. Pursuit brother are had fifteen distant has. Early had add equal china quiet visit. Appear an manner as no limits either praise..
                  </p>
                  <h4>Druna Patia</h4>
                  <span>Biology Student</span>
                </div>
              </div>
            </div>
            {/* <!-- Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="row align-items-center">
                <div className="col-lg-5 thumb">
                  {/* <img src="images/3_3.jpg" alt="Thumb"> */}
                </div>
                <div className="col-lg-7 info">
                  <p>
                    Procuring continued suspicion its ten. Pursuit brother are had fifteen distant has. Early had add equal china quiet visit. Appear an manner as no limits either praise..
                  </p>
                  <h4>Astron Brun</h4>
                  <span>Science Student</span>
                </div>
              </div>
            </div>
            {/* <!-- Single Item -->
            <!-- Single Item --> */}
            <div className="item">
              <div className="row align-items-center">
                <div className="col-lg-5 thumb">
                  {/* <img src="images/4_4.jpg" alt="Thumb"> */}
                </div>
                <div className="col-lg-7 info">
                  <p>
                    Procuring continued suspicion its ten. Pursuit brother are had fifteen distant has. Early had add equal china quiet visit. Appear an manner as no limits either praise..
                  </p>
                  <h4>Paol Druva</h4>
                  <span>Development Student</span>
                </div>
              </div>
            </div>
            {/* <!-- Single Item -->
            <!-- Single Item --> */}
            <div className="item">
              <div className="row align-items-center">
                <div className="col-lg-5 thumb">
                  {/* <img src="images/7_1.jpg" alt="Thumb"> */}
                </div>
                <div className="col-lg-7 info">
                  <p>
                    Procuring continued suspicion its ten. Pursuit brother are had fifteen distant has. Early had add equal china quiet visit. Appear an manner as no limits either praise..
                  </p>
                  <h4>Druna Patia</h4>
                  <span>Biology Student</span>
                </div>
              </div>
            </div>
            {/* <!-- Single Item --> */}
          </div>
        </div>
      </div>
      {/* <!-- End Testimonials -->

      <!-- Start Blog 
    ============================================= --> */}
      <div id="blog" className="blog-area default-padding bottom-less">
        <div className="container">
          <div className="row">
            <div className="site-heading text-center">
              <div className="col-lg-8 offset-lg-2">
                <h2>Latest News</h2>
                <p>
                  Able an hope of body. Any nay shyness article matters own removal nothing his forming. Gay own additions education satisfied the perpetual. If he cause manor happy. Without farther she exposed saw man led. Along on happy could cease green oh.
                </p>
              </div>
            </div>
          </div>
          <div className="blog-items">
            <div className="row">

              {/* <!-- Single Item --> */}
              <div className="col-lg-4 single-item">
                <div className="item">
                  <div className="thumb">
                    {/* <a href="#"><img src="images/1_4.jpg" alt="Thumb"></a> */}
                    <div className="date">
                      <h4><span>24</span> Nov, 2018</h4>
                    </div>
                  </div>
                  <div className="info">
                    <h4>
                      <a href="#">Objection happiness something</a>
                    </h4>
                    <p>
                      Sitting mistake towards his few country ask. You delighted two rapturous six depending objection happiness something the partiality unaffected
                    </p>
                    <a href="#">Read More <i className="fas fa-angle-double-right"></i></a>
                    <div className="meta">
                      <ul>
                        <li>
                          <a href="#"><i className="fas fa-user"></i> Author</a>
                        </li>
                        <li>
                          <a href="#"><i className="fas fa-comments"></i> 23 Comments</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Single Item --> */}
              {/* <!-- Single Item --> */}
              <div className="col-lg-4 single-item">
                <div className="item">
                  <div className="thumb">
                    {/* <a href="#"><img src="images/2_6.jpg" alt="Thumb"></a> */}
                    <div className="date">
                      <h4><span>12</span> Sep, 2018</h4>
                    </div>
                  </div>
                  <div className="info">
                    <h4>
                      <a href="#">Meant to learn of vexed</a>
                    </h4>
                    <p>
                      Sitting mistake towards his few country ask. You delighted two rapturous six depending objection happiness something the partiality unaffected
                    </p>
                    <a href="#">Read More <i className="fas fa-angle-double-right"></i></a>
                    <div className="meta">
                      <ul>
                        <li>
                          <a href="#"><i className="fas fa-user"></i> Author</a>
                        </li>
                        <li>
                          <a href="#"><i className="fas fa-comments"></i> 23 Comments</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Single Item --> */}
              {/* <!-- Single Item --> */}
              <div className="col-lg-4 single-item">
                <div className="item">
                  <div className="thumb">
                    {/* <a href="#"><img src="images/3_6.jpg" alt="Thumb"></a> */}
                    <div className="date">
                      <h4><span>29</span> Dec, 2018</h4>
                    </div>
                  </div>
                  <div className="info">
                    <h4>
                      <a href="#">Delightful up dissimilar</a>
                    </h4>
                    <p>
                      Sitting mistake towards his few country ask. You delighted two rapturous six depending objection happiness something the partiality unaffected
                    </p>
                    <a href="#">Read More <i className="fas fa-angle-double-right"></i></a>
                    <div className="meta">
                      <ul>
                        <li>
                          <a href="#"><i className="fas fa-user"></i> Author</a>
                        </li>
                        <li>
                          <a href="#"><i className="fas fa-comments"></i> 23 Comments</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Single Item --> */}

            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Blog -->

      <!-- Start Clients Area 
    ============================================= --> */}
      <div className="clients-area default-padding bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-4 info">
              <h4>Our largest education campus</h4>
              <p>
                Seeing rather her you not esteem men settle genius excuse. Deal say over you age from. Comparison new ham melancholy son themselves.
              </p>
            </div>
            <div className="col-md-8 clients">
              <div className="clients-items owl-carousel owl-theme text-center">
                <div className="single-item">
                  {/* <a href="#"><img src="images/1.png" alt="Clients"></a> */}
                </div>
                <div className="single-item">
                  {/* <a href="#"><img src="images/2.png" alt="Clients"></a> */}
                </div>
                <div className="single-item">
                  {/* <a href="#"><img src="images/3.png" alt="Clients"></a> */}
                </div>
                <div className="single-item">
                  {/* <a href="#"><img src="images/4.png" alt="Clients"></a> */}
                </div>
                <div className="single-item">
                  {/* <a href="#"><img src="images/5.png" alt="Clients"></a> */}
                </div>
                <div className="single-item">
                  {/* <a href="#"><img src="images/6.png" alt="Clients"></a> */}
                </div>
                <div className="single-item">
                  {/* <a href="#"><img src="images/7.png" alt="Clients"></a> */}
                </div>
                <div className="single-item">
                  {/* <a href="#"><img src="images/8.png" alt="Clients"></a> */}
                </div>
                <div className="single-item">
                  {/* <a href="#"><img src="images/9.png" alt="Clients"></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Clients Area -->

      <!-- Start Footer 
    ============================================= --> */}
     

    </>
  )
}

export default Header;