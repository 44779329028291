import "../../assets/css/footer.css";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function Footer() {
  const [currentPath, setCurrentPath] = useState("/");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const location = useLocation();

  // Updates currentPath whenever location changes
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  // Initialize Bootstrap tooltips after component mounts
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );
  }, []);

  // Function to handle phone click action
  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`; // Initiates call
  };

  return (
    <>
      {/* scroll up icon start */}
      {/* <div className="container">
        <button
          className="btn btn-secondary up_arrow_icon"
          onClick={scrollToTop}
        >
          <i className="fa-solid fa-arrow-up"></i>
        </button>
      </div> */}
      {/* scroll up icon end */}

      {/* mobile icons start */}
      <div className="container">
        {/* mobile icons start */}
        <div className="container">
          {/* mobile icons start */}
          <div
            className="dropdown-wrapper"
            onMouseEnter={() => setDropdownVisible(true)}
            onMouseLeave={() => setDropdownVisible(false)}
          >
            {/* Button that triggers the dropdown */}
            <button
              className="btn btn-warning up_arrow_icon"
              data-bs-toggle="tooltip"
              title="Branch 1"
            >
              <i className="fa-solid fa-phone"></i>
            </button>

            {/* Conditional rendering for the dropdown */}
            {dropdownVisible && (
              <div className="dropdown-menu show">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePhoneClick("+1234567890");
                  }}
                  className="dropdown-item"
                >
                  Branch 1
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePhoneClick("+0987654321");
                  }}
                  className="dropdown-item"
                >
                  Branch 2
                </a>
              </div>
            )}
          </div>
          {/* mobile icons end */}
        </div>
      </div>
      {/* mobile icons end */}

      {/* whatsapp icon start */}
      <div>
        <a href="#" data-bs-toggle="tooltip" data-bs-placement="top">
          <img
            className="whatsappicanfixed"
            src={require("../../assets/images/footer/whatsapp.png")}
            alt="WhatsApp Icon"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="What can I help you with!"
          />
        </a>
      </div>
      {/* whatsapp icon end */}

      {/* footer sections start */}
      <footer className="headerbg default-padding-top text-light">
        <div className="container">
          <div className="f-items">
            <div className="row">
              <div className="col-lg-3 col-md-6 item">
                <div className="">
                  <img
                    className="logofooter"
                    src={require("../../assets/images/scimitar_logo_slogan-bg.png")}
                    alt="Logo"
                  ></img>
                  {/* <!-- User Login Links --> */}
                  <div className="col-lg-4 d-flex justify-content-start mt-4">
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-facebook mx-2   h5"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-instagram mx-2 h5"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-youtube mx-3 h5"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa-linkedin mx-2 h5"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 item">
                <div className="f-item link">
                  <h4>Links</h4>
                  <ul className="commonPages">
                    <li>
                      <Link
                        to="/"
                        className={
                          currentPath === "/" ? "text-warning" : "text-white"
                        }
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about"
                        className={
                          currentPath === "/about"
                            ? "text-warning"
                            : "text-white"
                        }
                      >
                        About Us
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/courses"
                        className={
                          currentPath === "/course"
                            ? "text-warning"
                            : "text-white"
                        }
                      >
                        Courses
                      </Link>
                    </li> */}

                    <li>
                      <Link
                        to="/iat"
                        className={
                          currentPath === "/iat" ? "text-warning" : "text-white"
                        }
                      >
                        Industrial Automation
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/digitalmarketing"
                        className={
                          currentPath === "/digitalmarketing"
                            ? "text-warning"
                            : "text-white"
                        }
                      >
                        DigitalMarketing
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/blog"
                        className={
                          currentPath === "/blog"
                            ? "text-warning"
                            : "text-white"
                        }
                      >
                        Career
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact"
                        className={
                          currentPath === "/contact"
                            ? "text-warning"
                            : "text-white"
                        }
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 item">
                <div className="f-item address">
                  <h4>Our Branches</h4>
                  <ul className="commonPages">
                    <li>
                      <i className="fas fa-envelope"></i>
                      <p>
                        Email{" "}
                        <span>
                          <a href="mailto:support@validtheme.com">
                            scimitarhindustan@gmail.com
                          </a>
                        </span>
                      </p>
                    </li>
                    <li>
                      <i className="fas fa-map"></i>
                      <p>
                        Office <span>No:3, Sri P.R. Arcade. Salem, 636004</span>
                      </p>
                    </li>
                    <li>
                      <i className="fas fa-mobile"></i>
                      <p>
                        Mobile <span>+91 9080706050</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 item">
                <div className="f-item address mt-5">
                  <ul className="commonPages">
                    <li>
                      <i className="fas fa-envelope"></i>
                      <p>
                        Email{" "}
                        <span>
                          <a href="mailto:support@validtheme.com">
                            scimitarhindustan@gmail.com
                          </a>
                        </span>
                      </p>
                    </li>
                    <li>
                      <i className="fas fa-map"></i>
                      <p>
                        Office <span>No:3, Sri P.R. Arcade. Erode, 636004</span>
                      </p>
                    </li>
                    <li>
                      <i className="fas fa-mobile"></i>
                      <p>
                        Mobile <span>+91 9080706050</span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Start Footer Bottom --> */}
        <div className="footer-bottom">
          <div className="container ">
            <div className="row">
              <div className="col-lg-6">
                <p>
                  © Copyright 2024. All Rights Reserved by{" "}
                  <a href="#">Scimitar Hindustan</a>
                </p>
              </div>
              <div className="col-lg-6 text-end link">
                <ul>
                  <li>
                    {/* <a href="#">Developed By AalanTechSoft</a> */}
                    <p>Developed By AalanTechSoft</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Footer Bottom --> */}
      </footer>

      {/* footer sections end */}
    </>
  );
}
export default Footer;
